import { tableSortFilter } from '@/utils/arrayProcessing';
import { InvoiceRun, ProviderInvoice } from '../types';

export const INVOICE_RUNS_TABLE_HEAD = [
  { id: 'emptyCell' },
  { id: 'id', label: 'ID', align: 'left', width: '20px' },
  { id: 'start_date', label: 'Start Date', align: 'left' },
  { id: 'end_date', label: 'End Date', align: 'left' },
  { id: 'running', label: 'Status', align: 'left' },
  { id: 'processed_jobs', label: 'Job Processed', align: 'left' },
  { id: 'created_at', label: 'Created at', align: 'left' },
];

export const PROVIDER_INVOICES_TABLE_HEAD = [
  { id: 'emptyCell' },
  { id: 'id', label: 'ID', align: 'left' },
  { id: 'users.first_name', label: 'Provider', align: 'left' },
  { id: 'seen', label: 'Seen', align: 'left' },
  { id: 'accepted', label: 'Accepted', align: 'left' },
  { id: 'invoice_status.display', label: 'Status', align: 'left' },
  { id: 'date_from', label: 'Date From', align: 'left' },
  { id: 'date_to', label: 'Date To', align: 'left' },
  { id: 'due_date', label: 'Due Date', align: 'left' },
  { id: 'total', label: 'Total', align: 'left' },
  { id: '_count.provider_invoice_items', label: 'Jobs', align: 'left' },
  { id: 'cleaner_notes', label: 'Provider Notes', align: 'left' },
  { id: 'admin_notes', label: 'Admin notes', align: 'left' },
  { id: 'created_at', label: 'Created at', align: 'left' },
];

export function applySortFilterInvoices({
  tableData,
  comparator,
  filterStartDate,
  filterEndDate,
}: {
  tableData: InvoiceRun[];
  comparator: (a: any, b: any) => number;
  filterStartDate: string | null;
  filterEndDate: string | null;
}) {
  tableData = tableSortFilter(tableData, comparator);
  // console.log(filterEndDate, filterStartDate);

  if (filterStartDate && filterEndDate) {
    tableData = tableData.filter(
      (invoice) =>
        new Date(invoice.start_date) >= new Date(filterStartDate) &&
        new Date(invoice.start_date) <= new Date(filterEndDate)
    );
  }

  if (filterStartDate && !filterEndDate) {
    tableData = tableData.filter(
      (invoice) => new Date(invoice.start_date) >= new Date(filterStartDate)
    );
  }
  if (!filterStartDate && filterEndDate) {
    tableData = tableData.filter(
      (invoice) => new Date(invoice.start_date) <= new Date(filterEndDate)
    );
  }

  return tableData;
}

export function applySortFilterInvoicesList({
  tableData,
  comparator,
}: {
  tableData: ProviderInvoice[];
  comparator: (a: any, b: any) => number;
}) {
  tableData = tableSortFilter(tableData, comparator);

  return tableData;
}
