import AppProvider from '@/provider/app';
import { AppRoutes } from '@/routes';
import ScrollToTop from '@/components/ScrollToTop';

export default function App() {
  return (
    <AppProvider>
      <ScrollToTop />
      <AppRoutes />
    </AppProvider>
  );
}