import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '@/theme/mobiscroll.css';
//
import { useCallback, useMemo, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
// mobiscroll
import {
  CalendarNext,
  CalendarPrev,
  CalendarToday,
  Datepicker,
  Eventcalendar,
  MbscEventcalendarView,
  formatDate,
} from '@mobiscroll/react';
import { Button, Card, Stack } from '@mui/material';
//
import { ProviderTimeOffRequest, ProviderAvailabilityTable } from '..';
// API
import { getProviderAllDaysOff } from '../../api';
// utils
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import { DayOff } from '../../types';
import { calendarLabelColorPicker, generateEventLabel } from '../../utils';
import { SkeletonLoadingTableData } from '@/components/skeleton';

export default function ProviderAvailability() {
  const { userId } = useParams();
  const { errorMsg } = useSnackbarMsg();

  // =================
  //      QUERIES
  // =================
  // TODO: should we add a loading indicator to the calendar?
  const { data: providerDaysOff, isLoading: isDaysOffDataLoading } = useQuery({
    queryKey: ['providerTimeoff', userId],
    queryFn: () => getProviderAllDaysOff(String(userId)),
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching days off`);
    },
    select: (data) =>
      (data || []).map((dayOff: DayOff) => ({
        title: generateEventLabel(dayOff),
        start: new Date(
          (typeof dayOff.start_date === 'string'
            ? new Date(dayOff.start_date!)
            : dayOff.start_date!
          ).toLocaleString('en-US', { timeZone: 'Europe/London' })
        ),
        end: new Date(
          (typeof dayOff.end_date === 'string'
            ? new Date(dayOff.end_date!)
            : dayOff.end_date!
          ).toLocaleString('en-US', { timeZone: 'Europe/London' })
        ),
        color: calendarLabelColorPicker(dayOff.type_id),
      })),
    enabled: !!userId,
  });

  // ======================
  //       EDIT MODE
  // ======================
  const [editMode, setEditMode] = useState(false);
  const [currentTimeOffId, setCurrentTimeOffId] = useState<string | null>(null);
  const handleEdit = (id: string) => {
    setEditMode(true);
    setCurrentTimeOffId(id);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const exitEditMode = () => {
    setEditMode(false);
    setCurrentTimeOffId(null);
  };

  // ======================
  //    CALENDAR CONFIG
  // ======================

  const calendarViewConfig: MbscEventcalendarView = {
    timeline: {
      type: 'week',
      size: 1,
      startTime: '06:00',
      endTime: '23:00',
      resolutionVertical: 'day',
      resolutionHorizontal: 'hour',
    },
  };

  const [myRefDate, setMyRefDate] = useState<Date>();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [rangeVal, setRangeVal] = useState<any[]>([]);
  const [buttonText, setButtonText] = useState<string>('');

  const startDate = useRef<Date | null>(null);
  const endDate = useRef<Date | null>(null);

  const buttonProps = useMemo(() => {
    const content = <span className="mbsc-calendar-title">{buttonText}</span>;
    return {
      children: content,
      className: 'mbsc-calendar-button',
      variant: 'flat',
    };
  }, [buttonText]);

  // returns the number of days between two dates
  const getNrDays = useCallback(
    (start: any, end: any) =>
      Math.round(
        Math.abs((end.setHours(0) - start.setHours(0)) / (24 * 60 * 60 * 1000))
      ) + 1,
    []
  );

  // returns the formatted date
  const getFormattedRange = useCallback(
    (start: any, end: any) =>
      formatDate('MMM D, YYYY', new Date(start)) +
      (end && getNrDays(start, end) > 1
        ? ' - ' + formatDate('MMM D, YYYY', new Date(end))
        : ''),
    [getNrDays]
  );

  const handleDateChangeInHeader = useCallback((args: any) => {
    const date = args.value;
    setRangeVal(date);
    if (date[0] && date[1]) {
      startDate.current = date[0];
      endDate.current = date[1];
    }
  }, []);

  const handleCloseInHeader = useCallback(() => {
    if (startDate.current && endDate.current) {
      // navigate the calendar
      setCurrentDate(startDate.current);
      // set calendar view
      setMyRefDate(startDate.current);
    }
    setRangeVal([startDate.current, endDate.current]);
  }, []);

  const handlePageLoading = useCallback(
    (args: any) => {
      const sDate = args.firstDay;
      const end = args.lastDay;
      const eDate = new Date(
        end.getFullYear(),
        end.getMonth(),
        end.getDate() - 1,
        0
      );
      startDate.current = sDate;
      endDate.current = eDate;
      setTimeout(() => {
        // set button text
        setButtonText(getFormattedRange(sDate, eDate));
        // set range value
        setRangeVal([sDate, eDate]);
        // navigate the calendar
        setCurrentDate(sDate);
      });
    },
    [getFormattedRange]
  );

  const customHeaderWithNavButtons = () => (
    <Stack
      sx={{
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div>
        <Datepicker
          select="range"
          display="anchored"
          showOverlay={false}
          touchUi={true}
          buttons={[]}
          inputComponent={Button}
          inputProps={buttonProps}
          onClose={handleCloseInHeader}
          onChange={handleDateChangeInHeader}
          value={rangeVal}
        />
      </div>
      <div className="md-custom-range-view-controls">
        <CalendarPrev />
        <CalendarToday />
        <CalendarNext />
      </div>
    </Stack>
  );

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={2}>
        <ProviderTimeOffRequest
          editMode={editMode}
          currentTimeOffId={currentTimeOffId}
          exitEditMode={exitEditMode}
        />
        <Card
          sx={{ p: 2, width: '100%', '& .mbsc-timeline-row': { height: 35 } }}
        >
          {isDaysOffDataLoading || !providerDaysOff ? (
            <SkeletonLoadingTableData />
          ) : (
            <Eventcalendar
              selectedDate={currentDate}
              renderHeader={customHeaderWithNavButtons}
              view={calendarViewConfig}
              firstDay={1}
              data={providerDaysOff}
              dataTimezone="bst"
              onPageLoading={handlePageLoading}
              refDate={myRefDate}
              theme="ios"
              themeVariant="light"
              rowHeight={'equal'}
            />
          )}
        </Card>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
        <ProviderAvailabilityTable handleEdit={handleEdit} />
      </Stack>
    </Stack>
  );
}
