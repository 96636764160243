import axios from '@/utils/axios';
// types
import {
  User,
  UserDto,
  UserWithUpdatedRoles,
} from '@/features/users/shared/types/users';
import { Booking } from '@/features/bookings-management/types';

// GET
export async function getUsersByType(type: number): Promise<User[]> {
  const {
    data: { data },
  } = await axios.get(`/users/bytype/${type}`);
  return data;
}
export async function getDashboardUsers(): Promise<User[]> {
  const {
    data: { data },
  } = await axios.get('/users/bytype/3');
  return data;
}
export async function getProviders(): Promise<User[]> {
  const {
    data: { data },
  } = await axios.get('/users/bytype/2');
  return data;
}

export async function getActiveProviders(): Promise<User[]> {
  const {
    data: { data },
  } = await axios.get('/users/bytype/2/bystatus/1');
  return data;
}

export async function getClients(): Promise<User[]> {
  const {
    data: { data },
  } = await axios.get('/users/bytype/1');
  return data;
}

export async function getSingleUser(id: number): Promise<User> {
  const {
    data: { data },
  } = await axios.get(`/users/${id}`);

  return data;
}

// POST
export async function createNewUser(userData: UserDto): Promise<User> {
  const {
    data: { data },
  } = await axios.post(`/users`, userData);

  return data;
}

// PUT
export const updateUser = async (userID: number, userData: UserDto) =>
  axios.put(`/users/${userID}`, userData);

export const updateMultipleUsers = async (
  usersWithUpdatedRoles: UserWithUpdatedRoles[]
) => axios.post(`/users/update-multiple`, usersWithUpdatedRoles);

// DELETE
export const deleteUser = async (userID: number) =>
  axios.delete(`/users/${userID}`);

// UPLOAD IMAGE
export const uploadImage = async (formData: FormData) =>
  axios.post('/upload/public', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const resetUserPassword = async (userEmail: string) =>
  axios.post('/auth/reset-password', { email: userEmail });

// GET USERS (CLIENTS) BOOKINGS
export async function getUserBookings(userId: number): Promise<Booking[]> {
  const {
    data: { data },
  } = await axios.get(`/users/${userId}/bookings`);
  return data;
}

// GET USERS (PROVIDERS) JOBS
export async function getUserJobs(userId: number): Promise<Booking[]> {
  const {
    data: { data },
  } = await axios.get(`/users/${userId}/jobs`);
  return data;
}
