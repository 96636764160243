import { tableSortFilter } from '@/utils/arrayProcessing';
//
import { DayOff } from '../types';
import { Booking } from '@/features/bookings-management/types';
import { Review } from '@/features/reviews/types';
import { User } from '@/features/users/shared/types';
import { OffDaysRequestType } from '@/types';
//
import { ProviderInvoice } from '@/features/invoices/types';

// PROVIDERS
export function applySortFilterProviders({
  tableData,
  comparator,
  filterName,
  filterStatus,
  filterCity,
}: {
  tableData: User[];
  comparator: (a: any, b: any) => number;
  filterName: string;
  filterStatus: string;
  filterCity: number;
}) {
  tableData = tableSortFilter(tableData, comparator);

  if (filterName) {
    tableData = tableData.filter((item) => {
      const name = `${item.first_name} ${item.last_name}`;
      return (
        name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        item.email.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
      );
    });
  }
  if (filterStatus !== 'all') {
    tableData = tableData.filter((item) =>
      !item.user_statuses
        ? false
        : (item.user_statuses.display ?? '').toLowerCase() === filterStatus
    );
  }

  if (filterCity !== 0) {
    tableData = tableData.filter((user) => {
      const currentAddress = user.address[0];
      return currentAddress ? currentAddress.city_id === filterCity : false;
    });
  }

  return tableData;
}

// REVIEWS
export function applySortFilterReviews({
  tableData,
  comparator,
  searchFieldValue,
}: {
  tableData: Review[];
  comparator: (a: any, b: any) => number;
  searchFieldValue: string;
}) {
  tableData = tableSortFilter(tableData, comparator);
  const searchFieldLowerCase = searchFieldValue.toLowerCase();

  if (searchFieldValue) {
    tableData = tableData.filter((item) => {
      const clientFullName = `${item.client.first_name} ${item.client.last_name}`;
      const cleanerFullName = `${item.cleaner.first_name} ${item.cleaner.last_name}`;
      return (
        item.id.toString().indexOf(searchFieldValue) !== -1 ||
        clientFullName.toLowerCase().indexOf(searchFieldLowerCase) !== -1 ||
        cleanerFullName.toLowerCase().indexOf(searchFieldLowerCase) !== -1 ||
        item.review.toLowerCase().indexOf(searchFieldLowerCase) !== -1 ||
        item.rating.toString().indexOf(searchFieldValue) !== -1
      );
    });
  }

  return tableData;
}

// CLEANER JOBS
export function applySortFilterProviderJobs({
  tableData,
  comparator,
  searchFieldValue,
}: {
  tableData: Booking[];
  comparator: (a: any, b: any) => number;
  searchFieldValue: string;
}) {
  tableData = tableSortFilter(tableData, comparator);
  const searchFieldLowerCase = searchFieldValue.toLowerCase();

  if (searchFieldValue) {
    tableData = tableData.filter((item) => {
      const clientFullName = `${item.user?.first_name} ${item.user?.last_name}`;

      // Check if the search term matches the ID, client's full name, or email
      const matchesIdClientEmail =
        item.id.toString().indexOf(searchFieldValue) !== -1 ||
        clientFullName.toLowerCase().indexOf(searchFieldLowerCase) !== -1 ||
        item.user?.email.toLowerCase().indexOf(searchFieldLowerCase) !== -1;

      // Check if the search term matches any cleaner's first name or last name
      const matchesCleanerName = item.booking_cleaners?.some((cleaner) => {
        const cleanerFullName = `${cleaner.first_name} ${cleaner.last_name}`;
        return (
          cleanerFullName.toLowerCase().indexOf(searchFieldLowerCase) !== -1
        );
      });

      return matchesIdClientEmail || matchesCleanerName;
    });
  }

  return tableData;
}

// INVOICES
export function applySortFilterProviderInvoices({
  tableData,
  comparator,
  searchFieldValue,
}: {
  tableData: ProviderInvoice[];
  comparator: (a: any, b: any) => number;
  searchFieldValue: string;
}) {
  tableData = tableSortFilter(tableData, comparator);

  if (searchFieldValue) {
    tableData = tableData.filter(
      (item) => item.id.toString().indexOf(searchFieldValue) !== -1
    );
  }

  return tableData;
}

// CLEANER JOBS
export function applySortFilterProviderAvailability({
  tableData,
  comparator,
  searchFieldValue,
  availabilityTypeFilterValue,
  timeOffRequestTypes,
  filterStatus,
}: {
  tableData: DayOff[];
  comparator: (a: any, b: any) => number;
  searchFieldValue: string;
  availabilityTypeFilterValue: string;
  timeOffRequestTypes: OffDaysRequestType[];
  filterStatus: string;
}) {
  tableData = tableSortFilter(tableData, comparator);
  const searchFieldLowerCase = searchFieldValue.toLowerCase();

  if (searchFieldValue) {
    tableData = tableData.filter(
      (item) =>
        item.id.toString().indexOf(searchFieldValue) !== -1 ||
        String(item.notes).toLowerCase().indexOf(searchFieldLowerCase) !== -1
    );
  }

  if (availabilityTypeFilterValue !== 'all') {
    const currentTypeId = timeOffRequestTypes.find(
      (type) =>
        type.description.toLowerCase() ===
        availabilityTypeFilterValue.toLowerCase()
    );
    if (currentTypeId) {
      tableData = tableData.filter((item) => item.type_id === currentTypeId.id);
    }
  }
  if (filterStatus.toLowerCase() === 'expired') {
    tableData = tableData.filter((dayoff) => {
      if (dayoff.start_date) {
        const isPriceExpired = new Date(dayoff.start_date) < new Date();
        return isPriceExpired;
      } else {
        return false;
      }
    });
  }
  if (filterStatus.toLowerCase() === 'active') {
    tableData = tableData.filter((dayoff) => {
      if (dayoff.start_date) {
        const isPriceExpired = new Date(dayoff.start_date) >= new Date();
        return isPriceExpired;
      } else {
        return false;
      }
    });
  }
  return tableData;
}
