import { useParams, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { capitalCase } from 'change-case';
// @mui
import {
  Autocomplete,
  Card,
  Container,
  InputAdornment,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
//
import { HeaderBreadcrumbs, Page } from '@/components';
import {
  FormProvider,
  RHFDatepicker,
  RHFTextField,
} from '@/components/hook-form';
import RHFMultipleSelectCheckmarks from '@/components/hook-form/RHFMultipleSelectCheckmarks';
import { SkeletonLoadingTableData } from '@/components/skeleton';
// routes
import { PATH_COUPONS, couponsPath } from '../routes/paths';
import { PATH_AGENTDASHBOARD } from '@/features/agent-dashboard';
// api
import { getClients } from '@/features/users/shared/api';
import { createCoupon, getSingleCoupon, updateCoupon } from '../api';
import { getSettingsData } from '@/features/app/api';
// hooks + utils
import useSettings from '@/hooks/useSettings';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import { CouponSchema, couponDefaultValues } from '../utils';
// types
import { CouponFormValues } from '../types';
import useResponsive from '@/hooks/useResponsive';

export default function CouponCreate() {
  const navigate = useNavigate();
  const { themeStretch } = useSettings();
  const { couponId } = useParams();
  const { errorMsg, successMsg } = useSnackbarMsg();

  // ===============
  //     QUERIES
  // ===============
  const { data: clients, isLoading: isClientDataLoading } = useQuery({
    queryKey: ['clients'],
    queryFn: () => getClients(),
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching clients data`);
    },
  });

  const { data: settingsData, isLoading: isPlatformsDataLoading } = useQuery({
    queryKey: ['settings'],
    queryFn: () => getSettingsData(),
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching settings data `);
    },
    // TODO:
    staleTime: 24 * 60 * 60 * 1000, // update every 24 hours
    cacheTime: 24 * 60 * 60 * 1000, // update every 24 hours
  });

  const { data: currentCoupon } = useQuery({
    queryKey: ['coupons', couponId],
    queryFn: () => getSingleCoupon(Number(couponId)),
    onSuccess: (currentCoupon) => {
      // reset RHF values
      const currentClient = clients?.find(
        (client) => client.id === currentCoupon.user_id
      );
      !currentClient &&
        currentCoupon.user_id &&
        errorMsg(null, 'Client not found');

      resetFormValues({
        coupon_code: currentCoupon.code,
        description: currentCoupon.description,
        value: currentCoupon.value,
        value_type: currentCoupon.value_type
          ? capitalCase(currentCoupon.value_type)
          : '',
        status: currentCoupon.active ? 'active' : 'inactive',
        usage_limit: currentCoupon.usage_limit,
        start_date: currentCoupon.start_date,
        end_date: currentCoupon.end_date,
        coupon_platforms: currentCoupon.coupon_platforms,
        user_id: currentClient,
        drip_sessions: currentCoupon.drip_sessions,
      });
    },
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching coupon data`);
    },
    enabled: !!couponId && !!clients,
  });

  // ===============
  //    RHF + YUP
  // ===============

  const methods = useForm<CouponFormValues>({
    resolver: yupResolver(CouponSchema),
    defaultValues: couponDefaultValues,
  });

  const {
    control,
    reset: resetFormValues,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const formValues = useWatch({ control });

  // ===============
  //      SUBMIT
  // ===============
  const submitCoupon = async (data: CouponFormValues) => {
    const {
      coupon_code,
      description,
      value,
      value_type,
      usage_limit,
      start_date,
      end_date,
      user_id,
      status,
      coupon_platforms,
      drip_sessions,
    } = data;

    const couponData = {
      user_id: user_id?.id ?? null,
      code: coupon_code,
      description,
      value: Number(value),
      value_type: value_type.toUpperCase(),
      usage_limit: Number(usage_limit),
      start_date: new Date(start_date).toISOString(),
      end_date: new Date(end_date).toISOString(),
      active: status === 'active' ? true : false,
      coupon_platforms: coupon_platforms.map((cp) => cp.id),
      drip_sessions: drip_sessions ?? null,
    };

    try {
      if (couponId) {
        await updateCoupon(Number(couponId), couponData);
        successMsg(`Coupon #${couponId} updated successfully`);
      } else {
        await createCoupon(couponData);
        successMsg('Coupon created successfully');
      }
      navigate(couponsPath('list'));
    } catch (error) {
      errorMsg(
        error,
        `Something went wrong while ${
          couponId ? 'updating' : 'creating'
        } coupon`
      );
    }
  };

  // ========================================================

  const showSkeleton =
    !clients ||
    isClientDataLoading ||
    !settingsData ||
    isPlatformsDataLoading ||
    (couponId && !currentCoupon);

  const mdDown = useResponsive('down', 'md');
  const stackDirection = mdDown ? 'column' : 'row';
  const stackSpacing = 2;

  return (
    <Page title="Coupons: Create/edit">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!couponId ? 'Create a new coupon' : 'Edit coupon'}
          links={[
            { name: 'Dashboard', href: PATH_AGENTDASHBOARD.root },
            { name: 'Coupons', href: PATH_COUPONS.root },
            { name: !couponId ? 'New coupon' : 'Edit coupon' },
          ]}
        />

        {showSkeleton ? (
          <SkeletonLoadingTableData />
        ) : (
          <Card sx={{ py: 2, px: 4 }}>
            <FormProvider
              methods={methods}
              onSubmit={handleSubmit(submitCoupon)}
            >
              <Stack spacing={stackSpacing}>
                <Typography fontSize={20} fontWeight={600}>
                  Coupon details
                </Typography>

                {/* COUPON CODE, DESCRIPTION */}
                <Stack direction={stackDirection} spacing={stackSpacing}>
                  <RHFTextField name="coupon_code" label="Coupon code" />
                  <RHFTextField name="description" label="Description" />
                </Stack>

                {/* VALUE, VALUE TYPE */}
                <Stack direction={stackDirection} spacing={stackSpacing}>
                  <RHFTextField
                    name="value"
                    label="Value"
                    type="number"
                    InputProps={{
                      startAdornment: formValues.value_type &&
                        formValues.value_type === 'Fixed' && (
                          <InputAdornment position="start">£</InputAdornment>
                        ),
                      endAdornment: formValues.value_type &&
                        formValues.value_type === 'Percentage' && (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                    }}
                  />
                  <RHFTextField name="value_type" label="Value type" select>
                    {['Percentage', 'Fixed'].map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </RHFTextField>
                </Stack>

                {/* START + END DATE */}
                <Stack direction={stackDirection} spacing={stackSpacing}>
                  <RHFDatepicker
                    name="start_date"
                    label="Start date"
                    size="small"
                    disablePast
                  />
                  <RHFDatepicker
                    name="end_date"
                    label="End date"
                    size="small"
                    disablePast
                    minDate={formValues.start_date}
                  />
                </Stack>

                {/* USAGE LIMIT, PLATFORMS */}
                <Stack direction={stackDirection} spacing={stackSpacing}>
                  <RHFTextField
                    name="usage_limit"
                    label="Usage limit"
                    type="number"
                    inputProps={{
                      min: 0,
                      max: 1_000,
                      step: 1,
                    }}
                  />

                  <RHFMultipleSelectCheckmarks
                    options={settingsData?.platforms ?? []}
                    name="coupon_platforms"
                    label="Platforms"
                  />
                </Stack>

                {/* STATUS, CLIENT */}
                <Stack direction={stackDirection} spacing={stackSpacing}>
                  <RHFTextField name="status" label="Status" select>
                    {[
                      { id: 1, value: 'active', name: 'Active' },
                      { id: 2, value: 'inactive', name: 'Inactive' },
                    ].map(({ id, value, name }) => (
                      <MenuItem key={id} value={value}>
                        {name}
                      </MenuItem>
                    ))}
                  </RHFTextField>

                  {!clients || isClientDataLoading ? (
                    <Skeleton variant="rounded" />
                  ) : (
                    <Controller
                      name="user_id"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <Autocomplete
                          value={value}
                          fullWidth
                          size="small"
                          blurOnSelect
                          multiple={false}
                          noOptionsText="No such client"
                          options={clients}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          getOptionLabel={(option) =>
                            `${option.first_name ?? 'N/A'} ${
                              option.last_name ?? 'N/A'
                            }`
                          }
                          onChange={(_event, value) => onChange(value)}
                          renderOption={(props, option) => (
                            <MenuItem {...props} key={option.id}>
                              {`${option.first_name ?? 'N/A'} ${
                                option.last_name ?? 'N/A'
                              }`}
                            </MenuItem>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label="Select client" />
                          )}
                        />
                      )}
                    />
                  )}
                </Stack>

                {/* DRIP SESSION */}
                <Stack
                  direction={stackDirection}
                  spacing={stackSpacing}
                  sx={{ pr: mdDown ? 0 : 2 }}
                >
                  <RHFTextField
                    sx={{ width: mdDown ? '100%' : '50%' }}
                    name="drip_sessions"
                    label="Drip Sessions"
                    type="number"
                    value={formValues.drip_sessions ?? ''}
                  />
                </Stack>

                {/* ACTION BUTTONS */}
                <Stack direction="row" justifyContent="flex-end">
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    {`${couponId ? 'Update' : 'Create'}`} Coupon
                  </LoadingButton>
                </Stack>
              </Stack>
            </FormProvider>
          </Card>
        )}
      </Container>
    </Page>
  );
}
