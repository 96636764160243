// mui
import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
//
import Iconify, { TooltipIcon } from '@/components/Iconify';
//
import useResponsive from '@/hooks/useResponsive';
//types

type InvoiceRunsTableToolbarProps = {
  filterStartDate: string | null;
  filterEndDate: string | null;
  //
  handleDatepickerChange: (
    value: string | null,
    date_type: string,
    keyboardInputValue?: string | undefined
  ) => void;
  resetFilters: () => void;
};

export default function InvoiceRunsTableToolbar({
  filterStartDate,
  filterEndDate,
  //
  handleDatepickerChange,
  resetFilters,
}: InvoiceRunsTableToolbarProps) {
  const downLg = useResponsive('down', 'lg');

  const isFiltering = !!filterStartDate || !!filterEndDate;

  const xlDropdownGridItemSize = 2;

  return (
    <Grid
      container
      spacing={2}
      direction={{ xs: 'column', lg: 'row' }}
      sx={{ py: 2.5, px: 3 }}
    >
      <Grid item xs={6} sm={6} md={6} lg={6} xl={xlDropdownGridItemSize}>
        <DatePicker
          label="Start Date"
          value={filterStartDate}
          onChange={(date) => handleDatepickerChange(date, 'start')}
          renderInput={(params) => (
            <TextField {...params} size="small" fullWidth />
          )}
          componentsProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
        />
      </Grid>

      {/* END DATE */}
      <Grid item xs={6} sm={6} md={6} lg={6} xl={xlDropdownGridItemSize}>
        <DatePicker
          label="End Date"
          value={filterEndDate}
          minDate={filterStartDate}
          onChange={(date) => handleDatepickerChange(date, 'end')}
          renderInput={(params) => (
            <TextField {...params} size="small" fullWidth />
          )}
          componentsProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
        />
      </Grid>

      <Grid item>
        <Stack direction="row" spacing={1} alignItems="center">
          <TooltipIcon
            title="Using this filter you can check whether the start date of the invoice is beetween this date range"
            icon="eva:info-outline"
          />

          {downLg && (
            <Typography fontSize={12} fontStyle="italic">
              Using this filter you can check whether the start date of the
              invoice is beetween the selected date range
            </Typography>
          )}
        </Stack>
      </Grid>
      {isFiltering && (
        <Grid item xs={12} md={12} xl={2} justifyContent="center">
          <Button
            onClick={resetFilters}
            variant="text"
            color="inherit"
            sx={{ pt: 1 }}
            startIcon={
              <Iconify
                icon="eva:close-circle-outline"
                sx={{ width: 20, height: 20 }}
              />
            }
          >
            Reset filters
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
