import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
//
import {
  Box,
  Card,
  Container,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { HeaderBreadcrumbs, Page, Scrollbar } from '@/components';
import { SkeletonLoadingTable } from '@/components/skeleton';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
} from '@/components/table';
import { InvoicesRunTableRow, InvoiceRunsTableToolbar } from '../components';
// api
import { getAllInvoiceRuns } from '@/features/invoices/api';
// hookes + utils
import { useGetAccess } from '@/hooks/useAccess';
import useSettings from '@/hooks/useSettings';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import useTable, { emptyRows, getComparator } from '@/hooks/useTable';
//
import {
  INVOICE_RUNS_TABLE_HEAD,
  applySortFilterInvoices,
} from '@/features/invoices/utils';
import { PATH_USER } from '@/features/app';
import { consoleError } from '@/utils/consoleError';

export default function InvoiceRunList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
    setSelected,
    setPage,
  } = useTable();

  const allow = useGetAccess('reviews'); // TODO: add this to the relevant components
  const { errorMsg } = useSnackbarMsg();
  const { themeStretch } = useSettings();
  const [filterStartDate, setFilterStartDate] = useState<string | null>(null);
  const [filterEndDate, setFilterEndDate] = useState<string | null>(null);

  // =================
  //      QUERIES
  // =================
  const { data: invoiceRuns, isLoading: invoicesRunLoading } = useQuery({
    queryKey: ['invoiceRuns'],
    queryFn: getAllInvoiceRuns,
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching invoices`);
    },
  });

  const resetFilters = () => {
    setFilterStartDate(null);
    setFilterEndDate(null);
  };

  const handleDatepickerChange = (date: string | null, date_type: string) => {
    switch (date_type) {
      case 'start':
        setFilterStartDate(date);
        break;
      case 'end':
        setFilterEndDate(date);
        break;
      default:
        consoleError('InvoiceRun', 'handleDatepickerChange()');
    }
    setSelected([]);
    setPage(0);
  };

  const dataFiltered = applySortFilterInvoices({
    tableData: invoiceRuns || [],
    filterStartDate,
    filterEndDate,
    comparator: getComparator(order, orderBy),
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound = !dataFiltered.length;

  return (
    <Page title="Invoices Run">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Invoices Run"
          links={[
            { name: 'Dashboard', href: PATH_USER.root },
            { name: 'Invoices Run', href: '' },
          ]}
        />
        <Card>
          {invoicesRunLoading ? (
            <SkeletonLoadingTable />
          ) : (
            <>
              <InvoiceRunsTableToolbar
                filterStartDate={filterStartDate}
                filterEndDate={filterEndDate}
                handleDatepickerChange={handleDatepickerChange}
                resetFilters={resetFilters}
              />

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                  <Table size={dense ? 'small' : 'medium'}>
                    <TableHeadCustom
                      order={order}
                      orderBy={orderBy}
                      headLabel={INVOICE_RUNS_TABLE_HEAD}
                      onSort={onSort}
                    />
                    <TableBody>
                      {dataFiltered
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((preview) => (
                          <InvoicesRunTableRow key={preview.id} row={preview} />
                        ))}

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(
                          page,
                          rowsPerPage,
                          dataFiltered.length
                        )}
                      />

                      <TableNoData
                        isNotFound={isNotFound}
                        title="No invoice runs found"
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>

              <Box sx={{ position: 'relative' }}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={dataFiltered.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                />

                <FormControlLabel
                  control={<Switch checked={dense} onChange={onChangeDense} />}
                  label="Dense"
                  sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                />
              </Box>
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}
