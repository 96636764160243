import { isBefore, isAfter, isWithinInterval } from 'date-fns';
// global util
import { consoleError } from '@/utils/consoleError';
// types
import { Booking } from '../types';
import { ServiceExtra } from '@/types';
import { Service } from '@/features/services-management/types';
import { tableSortFilter } from '@/utils/arrayProcessing';
type FilterBookingsInputs = {
  tableData: Booking[];
  comparator: (a: any, b: any) => number;
  searchFieldValue: string;
  filterServiceId: number;
  filterServiceTypeId: number;
  filterStartDate: string | null;
  filterEndDate: string | null;
  filterStatus: string;
  //
  servicesWithTheirVariants: { id: number; name: string; variants: number[] }[];
};

export function applySortFilterBookings({
  tableData,
  comparator,
  searchFieldValue,
  filterServiceId,
  filterServiceTypeId,
  filterStartDate,
  filterEndDate,
  filterStatus,
  //
  servicesWithTheirVariants,
}: FilterBookingsInputs) {
  tableData = tableSortFilter(tableData, comparator);

  // ===================
  //     SEARCH BAR
  // ===================
  if (searchFieldValue) {
    const seachFieldValueLowerCase = searchFieldValue.toLowerCase();

    tableData = tableData.filter((booking) => {
      const user = `${booking.user?.first_name} ${booking.user?.last_name}`;
      console.log(booking.user);
      return (
        (booking.id ?? 0).toString().indexOf(seachFieldValueLowerCase) !== -1 ||
        user.toLowerCase().indexOf(seachFieldValueLowerCase) !== -1 ||
        (booking.user &&
          booking.user.email &&
          booking.user.email.toLowerCase().indexOf(seachFieldValueLowerCase) !==
            -1) ||
        booking.address.postcode
          .toLowerCase()
          .indexOf(seachFieldValueLowerCase) !== -1 ||
        booking.address.postcode
          .toLowerCase()
          .replace(/\s/g, '')
          .indexOf(seachFieldValueLowerCase) !== -1 ||
        booking.address.formatted_address
          .toLowerCase()
          .indexOf(seachFieldValueLowerCase) !== -1 ||
        (booking.total_amount ?? 0)
          .toString()
          .indexOf(seachFieldValueLowerCase) !== -1
      );
    });
  }

  // ===================
  //    SERVICE NAME
  // ===================
  if (filterServiceId !== 0) {
    tableData = tableData.filter((booking) => {
      const selectedService = servicesWithTheirVariants.find(
        (service) => service.id === filterServiceId
      );
      return selectedService?.variants.includes(booking.service_variant_id);
    });
  }

  // ==========================
  //    SERVICE TYPE/VARIANT
  // ==========================
  if (filterServiceTypeId !== 0) {
    tableData = tableData.filter(
      (booking) => booking.service_variant_id === filterServiceTypeId
    );
  }

  // ===================
  //        DATES
  // ===================
  if (filterStartDate && filterEndDate) {
    tableData = tableData.filter((booking) => {
      if (booking.created_at.length > 0) {
        return isWithinInterval(new Date(booking.created_at), {
          start: new Date(filterStartDate),
          end: new Date(filterEndDate),
        });
      }
      return false;
    });
  }

  if (filterStartDate) {
    tableData = tableData.filter((booking) => {
      if (booking.created_at.length > 0) {
        return isAfter(new Date(booking.created_at), new Date(filterStartDate));
      }
      return false;
    });
  }

  if (filterEndDate) {
    tableData = tableData.filter((booking) => {
      if (booking.created_at.length > 0) {
        return isBefore(new Date(booking.created_at), new Date(filterEndDate));
      }
      return false;
    });
  }

  // ====================
  //    BOOKING STATUS
  // ====================
  if (filterStatus.toLowerCase() !== 'all') {
    tableData = tableData.filter(
      (booking) =>
        booking.booking_status.display.toLowerCase() ===
        filterStatus.toLowerCase()
    );
  }

  return tableData;
}

// ====================================================
//     CALCULATING FIGURES FOR BOOKING STATUS TABS
// ====================================================
// BookingsList.tsx >> Tabs

export function calculateBookingStatusFigures(
  statusType: string,
  tableData: Booking[]
) {
  // filter data based on status type and return length of array
  // ie. (12)Completed, (234) Cancelled, (65)Draft ...
  switch (statusType.toLowerCase()) {
    case 'all':
      return tableData.length;
    case 'active':
    case 'incomplete':
    case 'pending':
    case 'completed':
    case 'paused':
    case 'cancelled':
    case 'new':
      tableData = tableData.filter(
        (item) =>
          item.booking_status.display.toLowerCase() === statusType.toLowerCase()
      );
      return tableData.length;
    default:
      consoleError('calculateFiguresForStatuses()');
      return '!!error!!';
  }
}

export const findServiceVariantId = (
  variantName: string,
  variants: Service[]
) => {
  const found = variants.find((variant) =>
    variant.name.toLowerCase().includes(variantName.toLowerCase())
  );
  return found ? found.id : 0;
};

export function transformExtraIds(extras: null | undefined | string) {
  // the value is either null, empty string, or numbers as string separated by |
  // ie. extras: null, extras: '', extras: '1', extras: '1|2', extras: '1|4|6'
  // the returned array is used in retrieveExtrasFromId(), see below and slices/index.ts

  if (!extras || extras?.trim() === '') {
    // If extras is null, undefined, or an empty string, return an empty array
    return [];
  } else {
    // Split by '|' and convert to numbers, filtering out invalid numbers (NaN)
    return extras
      .split('|')
      .map(Number)
      .filter((id) => !isNaN(id)); // Filter out any NaN values
  }
}

export function retrieveExtrasFromId(
  ids: any[],
  arrayOfExtraObjects: ServiceExtra[]
) {
  if (arrayOfExtraObjects.length === 0) return [];
  const extrasToRetrun = arrayOfExtraObjects.reduce((arrayToReturn, extra) => {
    if (ids.includes(extra.id)) arrayToReturn.push(extra);
    return arrayToReturn;
  }, [] as ServiceExtra[]);
  return extrasToRetrun;
}

/**
 * @param {string} pets - The string of pets separated by '|'.
 * @returns {string[]} - An array of pets.
 *
 * @description This function takes a string of pets separated by '|' and returns an array of pets.
 */
export const transformPets = (pets: string) => {
  if (pets === '' || !pets) return [];
  return pets.split('|');
};

// =================
//    TABLE HEAD
// =================

export const BOOKINGS_TABLE_HEAD = [
  { id: 'id', label: 'ID', align: 'left' },
  { id: 'booking_service.service_name', label: 'Service name', align: 'left' },
  { id: 'user.first_name', label: 'Customer Details', align: 'left' },
  { id: 'address.name', label: 'Address name', align: 'left' },
  { id: 'address.postcode', label: 'Postcode', align: 'left' },
  { id: 'assigned_to', label: 'Assigned', align: 'left' },
  { id: 'created_at', label: 'Date Added', align: 'left' },
  { id: 'total_amount', label: 'Amount', align: 'left' },
  { id: 'booking_status_id', label: 'Status', align: 'left' },
  { id: '3-dot-menu' },
];
