import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
// @mui
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Iconify } from '@/components';
import DialogSimple from '@/components/dialog/DialogSimple';
import { InvoicePDF } from '.';
// hooks + utils
import useToggle from '@/hooks/useToggle';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import { fDate } from '@/utils/formatTime';
import { regenerateInvoice, updateInvoice } from '../../api';
// types
import { ProviderInvoice } from '../../types';

type Props = {
  invoice: ProviderInvoice;
};

export default function InvoiceToolbar({ invoice }: Props) {
  // TODO: add permissin check to buttons
  const {
    toggle: isViewInvoiceDialogOpen,
    onOpen: openViewInvoiceDialog,
    onClose: closeViewInvoiceDialog,
  } = useToggle();
  const {
    toggle: isConfirmPaymentOpen,
    onOpen: openConfirmPaymentDialog,
    onClose: closeConfirmPaymentDialog,
  } = useToggle();

  const { errorMsg, successMsg } = useSnackbarMsg();
  const queryClient = useQueryClient();

  const regenerateInvoiceMutation = useMutation({
    mutationFn: () => regenerateInvoice(Number(invoice.id)),
    onSuccess: () => {
      successMsg('Invoice successfully regenerated');
      queryClient.invalidateQueries(['invoiceDetails', invoice.id]);
    },
    onError: (error) => {
      errorMsg(error, 'Failed to regenerate invoice');
    },
  });

  const markInvoicePaidMutation = useMutation({
    mutationFn: () => updateInvoice(invoice.id, { status_id: 2 }),
    onSuccess: () => {
      successMsg('Invoice status successfully updated');
      queryClient.invalidateQueries(['invoiceDetails', invoice.id]);
    },
    onError: (error) => {
      errorMsg(error, 'Failed to update invoice status');
    },
  });

  const markInvoicePaid = () => markInvoicePaidMutation.mutate();

  const handleRegenerateInvoice = () => regenerateInvoiceMutation.mutate();

  return (
    <>
      {invoice.regenerate ? (
        <Stack direction="row" spacing={1} alignItems="center" sx={{ pb: 2 }}>
          <LoadingButton
            onClick={handleRegenerateInvoice}
            loading={regenerateInvoiceMutation.isLoading}
            variant="contained"
            color={'info'}
            startIcon={<Iconify icon="eva:refresh-outline" />}
            sx={{ m: 2 }}
          >
            Regenerate Invoice
          </LoadingButton>
          <Typography fontStyle="italic">
            *Job has been modified. Requires new re-generation.
          </Typography>
        </Stack>
      ) : (
        <Stack
          spacing={2}
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
          alignItems={{ sm: 'center' }}
          sx={{ mb: 5 }}
        >
          <Stack direction="row" spacing={1}>
            <Tooltip title="View">
              <IconButton onClick={openViewInvoiceDialog}>
                <Iconify icon={'eva:eye-fill'} />
              </IconButton>
            </Tooltip>

            <PDFDownloadLink
              document={<InvoicePDF invoice={invoice} />}
              fileName={
                'getsetclean_invoice_' +
                String(invoice.id) +
                '_' +
                fDate(invoice.created_at)
              }
              style={{ textDecoration: 'none' }}
            >
              {({ loading }) => (
                <Tooltip title="Download">
                  <IconButton>
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      <Iconify icon={'eva:download-fill'} />
                    )}
                  </IconButton>
                </Tooltip>
              )}
            </PDFDownloadLink>

            {/* <Tooltip title="Print">
            <IconButton>
              <Iconify icon={'eva:printer-fill'} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Send">
            <IconButton>
              <Iconify icon={'ic:round-send'} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Share">
            <IconButton>
              <Iconify icon={'eva:share-fill'} />
            </IconButton>
          </Tooltip> */}
          </Stack>

          {invoice.status_id === 1 && (
            <Button
              color="inherit"
              variant="outlined"
              startIcon={<Iconify icon="eva:checkmark-fill" />}
              sx={{ alignSelf: 'flex-end' }}
              onClick={openConfirmPaymentDialog}
            >
              Mark as Paid
            </Button>
          )}
        </Stack>
      )}

      <Dialog fullScreen open={isViewInvoiceDialogOpen}>
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <DialogActions
            sx={{
              zIndex: 9,
              padding: '12px !important',
              boxShadow: (theme) => theme.customShadows.z8,
            }}
          >
            <Tooltip title="Close">
              <IconButton color="inherit" onClick={closeViewInvoiceDialog}>
                <Iconify icon={'eva:close-fill'} />
              </IconButton>
            </Tooltip>
          </DialogActions>
          <Box sx={{ flexGrow: 1, height: '100%', overflow: 'hidden' }}>
            <PDFViewer width="100%" height="100%" style={{ border: 'none' }}>
              <InvoicePDF invoice={invoice} />
            </PDFViewer>
          </Box>
        </Box>
      </Dialog>

      <DialogSimple
        dialogTitle="Confirm change in payment status"
        open={isConfirmPaymentOpen}
        onClickButtonClose={closeConfirmPaymentDialog}
        onClickButtonLoading={markInvoicePaid}
        loadingButtonText="Confirm"
        disagreeButtonColor="inherit"
      >
        <Box sx={{ px: 3, py: 1.5 }}>
          Are you sure you want to change the payment status to{' '}
          <span style={{ fontWeight: 800 }}>Paid</span>?
        </Box>
      </DialogSimple>
    </>
  );
}
