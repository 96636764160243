import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Slide } from '@mui/material';
//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
//
import {
  RightSidebar,
  RightChatBar,
  RightSidebarScheduler,
} from '@/features/app/components';
// redux
import { dispatch, useSelector } from '@/stores';
import { setSelectedMenubarIconId } from '@/features/app/slices';
// hooks
import useSettings from '@/hooks/useSettings';
import useResponsive from '@/hooks/useResponsive';
import useCollapseDrawer from '@/hooks/useCollapseDrawer';
import useToggle from '@/hooks/useToggle';
// utils
import { RIGHT_SIDEBAR_MENU_OPTIONS } from '@/features/app/utils';
import { consoleError } from '@/utils/consoleError';
// config
import { HEADER, NAVBAR, RIGHT_SIDEBAR } from '@/config';
// chat-stream
import { BrowserNotificationDialog } from '@/features/chat-stream/components';
import { enableBrowserNotifications } from '@/features/chat-stream/utils';
// types

type MainStyleProps = {
  collapseClick: boolean;
};

const MainStyle = styled('main', {
  shouldForwardProp: (prop) =>
    prop !== 'collapseClick' && prop !== 'isAgentModeOn',
})<MainStyleProps>(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    padding: 16,
    paddingBottom: 16,
    width: `calc(100% - ${
      NAVBAR.DASHBOARD_WIDTH + RIGHT_SIDEBAR.BASE_WIDTH
    }px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ============================================================

export default function DashboardLayout() {
  const { collapseClick } = useCollapseDrawer();
  const { pathname } = useLocation();
  const { themeLayout } = useSettings();
  const { toggle: isDialogOpen, onClose: closeDialog } = useToggle(true);
  const isDesktop = useResponsive('up', 'lg');

  const { selectedMenubarIconId } = useSelector((state) => state.rightSideBar);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // hide the right sidebar Slide component menu the route changes
    dispatch(setSelectedMenubarIconId(0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderRightSidebarMenuContent = (slug: string) => {
    switch (slug) {
      case 'chat':
        return <RightChatBar />;
      case 'scheduler':
        return <RightSidebarScheduler />;
      default:
        consoleError('DashboardLayout', 'renderRightSidbarMenuOptions()');
    }
  };

  // CHAT DIALOG
  const handleEnableBrowserNotifications = () => {
    enableBrowserNotifications();
    closeDialog();
  };

  const showDialog =
    isDialogOpen &&
    typeof Notification !== 'undefined' &&
    Notification.permission === 'default';

  // ==================================

  const verticalLayout = themeLayout === 'vertical';

  if (verticalLayout) {
    return (
      <>
        <DashboardHeader
          onOpenSidebar={() => setOpen(true)}
          verticalLayout={verticalLayout}
        />

        {/* {isDesktop ? (
          <NavbarHorizontal />
        ) : ( */}
        <NavbarVertical
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
        />
        {/* )} */}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
        </Box>
      </>
    );
  }

  return (
    <>
      {/* chat notifications */}
      {showDialog && (
        <BrowserNotificationDialog
          isDialogOpen={showDialog}
          onEnable={handleEnableBrowserNotifications}
          closeDialog={closeDialog}
        />
      )}

      <Box
        sx={{
          display: { lg: 'flex', xs: 'flex' },
          minHeight: { lg: 1, xs: '100vh' },
        }}
      >
        {!isDesktop && (
          <DashboardHeader
            onOpenSidebar={() => setOpen(true)}
            verticalLayout={verticalLayout}
          />
        )}

        <NavbarVertical
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
        />
        <MainStyle collapseClick={collapseClick}>
          <Outlet />
        </MainStyle>
        <Box>
          <>
            {/* slide over menu content */}
            {RIGHT_SIDEBAR_MENU_OPTIONS.map((menuOption) => (
              <Slide
                key={menuOption.id}
                in={selectedMenubarIconId === menuOption.id}
                direction="left"
                mountOnEnter
                unmountOnExit
                timeout={{ enter: 500, exit: 300 }}
              >
                <div
                  style={{
                    zIndex: 2,
                    position: 'fixed',
                    width: menuOption.width,
                    right: 60,
                    boxShadow: '0px 0px 10px #232931',
                  }}
                >
                  {renderRightSidebarMenuContent(menuOption.slug)}
                </div>
              </Slide>
            ))}
          </>
        </Box>
        <RightSidebar />
      </Box>
    </>
  );
}
