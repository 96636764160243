import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
//
import { Container } from '@mui/material';
import { HeaderBreadcrumbs, Page } from '@/components';
import { InvoiceDetails } from '../components/providerInvoiceDetails';
import { SkeletonLoadingTable } from '@/components/skeleton';
// api
import { getProviderInvoice } from '@/features/invoices/api';
// hookes + utils
import useSettings from '@/hooks/useSettings';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import { PATH_USER } from '@/features/app';
import { invoicesPath } from '../routes/paths';

export default function InvoiceRunInvoicesList() {
  const { errorMsg } = useSnackbarMsg();
  const { themeStretch } = useSettings();
  const { invoiceId } = useParams();

  // =================
  //      QUERIES
  // =================
  const { data: invoiceDetails, isLoading: invoiceLoading } = useQuery({
    queryKey: ['invoiceDetails', invoiceId],
    queryFn: () => getProviderInvoice(Number(invoiceId)),
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching invoice`);
    },
  });

  const showSkeleton = !invoiceDetails || invoiceLoading;

  return (
    <Page title="Provider Invoice Details">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Provider Invoice Details"
          links={[
            { name: 'Dashboard', href: PATH_USER.root },
            { name: 'Invoices Run', href: invoicesPath('list') },
            {
              name: `Invoices Run #${invoiceDetails?.invoice_run_id || ''}`,
              href: invoicesPath('providerRunInvoicesList', {
                runId: String(invoiceDetails?.invoice_run_id),
              }),
            },
            { name: `INV-${invoiceDetails?.id}` || '' },
          ]}
        />
        {showSkeleton ? (
          <SkeletonLoadingTable />
        ) : (
          <InvoiceDetails invoice={invoiceDetails} />
        )}
      </Container>
    </Page>
  );
}
