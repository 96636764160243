import { useState } from 'react';
import { useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
//
import {
  Box,
  Card,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { Scrollbar } from '@/components';
import { SkeletonLoadingTable } from '@/components/skeleton';
import { ToolbarWithSearchAndStatus } from '@/components/toolbar';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
} from '@/components/table';
import { InvoicesRunInvoicesListTableRow } from '@/features/invoices/components';
//
import { getProviderInvoices } from '@/features/invoices/api';
// hookes + utils
import useTable, { emptyRows, getComparator } from '@/hooks/useTable';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import { applySortFilterProviderInvoices } from '../../utils';
import { PROVIDER_INVOICES_TABLE_HEAD } from '@/features/invoices/utils';
//

export default function ProviderInvoices() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    //
    selected,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { errorMsg } = useSnackbarMsg();
  const { userId } = useParams();
  const [searchFieldValue, setSearchFieldValue] = useState('');

  // =================
  //      QUERIES
  // =================
  const { data: providerInvoices, isLoading: invoicesLoading } = useQuery({
    queryKey: ['providerInvoices', userId],
    queryFn: () => getProviderInvoices(Number(userId)),
    onError: (error: any) => {
      errorMsg(error, `Something went wrong while fetching reviews`);
    },
  });

  const dataFiltered = applySortFilterProviderInvoices({
    tableData: providerInvoices || [],
    comparator: getComparator(order, orderBy),
    searchFieldValue,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound = !dataFiltered.length && !!searchFieldValue;

  const PROVIDER_INVOICE_TABLE_HEAD_WITHOUT_PROVIDER =
    PROVIDER_INVOICES_TABLE_HEAD.filter(
      (column) => column.id !== 'users.first_name'
    );
  return (
    <Card>
      {invoicesLoading ? (
        <SkeletonLoadingTable />
      ) : (
        <>
          <ToolbarWithSearchAndStatus
            filterText={searchFieldValue}
            onFilterText={setSearchFieldValue}
            labels={{ search: 'Search in invoices...' }}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={PROVIDER_INVOICE_TABLE_HEAD_WITHOUT_PROVIDER}
                  rowCount={dataFiltered.length}
                  numSelected={selected.length}
                  onSort={onSort}
                />
                <TableBody>
                  {dataFiltered
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((preview) => (
                      <InvoicesRunInvoicesListTableRow
                        key={preview.id}
                        row={preview}
                        hideProvider={true}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(
                      page,
                      rowsPerPage,
                      dataFiltered.length
                    )}
                  />

                  <TableNoData
                    isNotFound={isNotFound}
                    title="No invoices found"
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </>
      )}
    </Card>
  );
}
