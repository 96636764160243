import axios from '@/utils/axios';
import { InvoiceRun, ProviderInvoice } from '../types';

export async function getAllInvoiceRuns(): Promise<InvoiceRun[]> {
  const {
    data: { data },
  } = await axios.get(`/invoices/run`);
  return data;
}

export async function getAllProviderInvoicesForRun(
  id: number
): Promise<ProviderInvoice[]> {
  const {
    data: { data },
  } = await axios.get(`/invoices/provider/run/${id}`);
  return data;
}

export async function getProviderInvoices(
  provider_id: number
): Promise<ProviderInvoice[]> {
  const {
    data: { data },
  } = await axios.get(`/invoices/provider/${provider_id}`);
  return data;
}

export async function getProviderInvoice(
  invoice_id: number
): Promise<ProviderInvoice> {
  const {
    data: { data },
  } = await axios.get(`/invoices/provider/view/${invoice_id}`);
  return data;
}

export async function startInvoiceRun() {
  // params; cutoff_date, days_count, isDryRun = false
  const {
    data: { data },
  } = await axios.get(`/invoices/run/start`);
  return data;
}

export async function updateInvoice(
  invoiceId: number,
  invoiceDto: Partial<ProviderInvoice>
): Promise<ProviderInvoice> {
  const {
    data: { data },
  } = await axios.put(`/invoices/${invoiceId}`, invoiceDto);
  return data;
}

//
export async function regenerateInvoiceRun(id: number) {
  const {
    data: { data },
  } = await axios.get(`/invoices/regenerate/run/${id}`);
  return data;
}
export async function regenerateInvoice(id: number) {
  const {
    data: { data },
  } = await axios.get(`/invoices/regenerate/invoice/${id}`);
  return data;
}
