import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Card,
  Grid,
  Table,
  Divider,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  TableContainer,
} from '@mui/material';
//
import { InvoiceToolbar } from '.';
import { TableRowLink } from '@/components/table';
import { Label, Image, Scrollbar } from '@/components';
// utils
import { fDate } from '@/utils/formatTime';
import { fCurrency } from '@/utils/formatNumber';
import { bookingsManagementPath } from '@/features/bookings-management';
//
import { ProviderInvoice } from '../../types';
import { LabelColors } from '@/types';
// ----------------------------------------------------------------------

const RowResultStyle = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  invoice: ProviderInvoice;
};

export default function InvoiceDetails({ invoice }: Props) {
  const theme = useTheme();

  const {
    id,
    due_date,
    total,
    created_at,
    invoice_status,
    provider_invoice_items,
  } = invoice;

  return (
    <>
      <InvoiceToolbar invoice={invoice} />

      <Card sx={{ pt: 5, px: 5 }}>
        <Grid container>
          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Image
              disabledEffect
              visibleByDefault
              alt="logo"
              src="/logo/logo_full.svg"
              sx={{ maxWidth: 120 }}
            />
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Box sx={{ textAlign: { sm: 'right' } }}>
              <Label
                variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                color={
                  invoice_status
                    ? (invoice_status.style_class as LabelColors)
                    : 'default'
                }
                sx={{ textTransform: 'uppercase', mb: 1, fontSize: 18 }}
              >
                {invoice_status.value}
              </Label>

              <Typography variant="h6">{`INV-${id}`}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography
              paragraph
              variant="overline"
              sx={{ color: 'text.disabled' }}
            >
              Invoice from
            </Typography>
            {/* <Typography variant="body2">{invoiceFrom.name} NAme</Typography>
            <Typography variant="body2">{invoiceFrom.address}</Typography>
            <Typography variant="body2">Phone: {invoiceFrom.phone}</Typography> */}

            <Typography variant="body2">Name</Typography>
            <Typography variant="body2">Address</Typography>
            <Typography variant="body2">Phone: 09929323</Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography
              paragraph
              variant="overline"
              sx={{ color: 'text.disabled' }}
            >
              Invoice to
            </Typography>
            {/* <Typography variant="body2">{invoiceTo.name}</Typography>
            <Typography variant="body2">{invoiceTo.address}</Typography>
            <Typography variant="body2">Phone: {invoiceTo.phone}</Typography> */}

            <Typography variant="body2">To Name</Typography>
            <Typography variant="body2">Address</Typography>
            <Typography variant="body2">Phone: 043243243</Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography
              paragraph
              variant="overline"
              sx={{ color: 'text.disabled' }}
            >
              date create
            </Typography>
            <Typography variant="body2">{fDate(created_at)}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography
              paragraph
              variant="overline"
              sx={{ color: 'text.disabled' }}
            >
              Due date
            </Typography>
            <Typography variant="body2">{fDate(due_date)}</Typography>
          </Grid>
        </Grid>

        <Scrollbar>
          <TableContainer sx={{ minWidth: 960 }}>
            <Table>
              <TableHead
                sx={{
                  borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                  '& th': { backgroundColor: 'transparent' },
                }}
              >
                <TableRow>
                  <TableCell width={40}>#</TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="left">Hours</TableCell>
                  <TableCell align="right">Price/h</TableCell>
                  <TableCell align="right">Cleaning Subtotal</TableCell>
                  <TableCell align="center" width="100">
                    Cleaning Products
                  </TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {provider_invoice_items.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      borderBottom: (theme) =>
                        `solid 1px ${theme.palette.divider}`,
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell align="left">
                      {row.booking_sessions ? ( // on some occasions it is null
                        <Box sx={{ maxWidth: 560 }}>
                          <Typography variant="subtitle2">
                            <TableRowLink
                              linkText={
                                '#' + row.job_id + ':' + row.description
                              }
                              linkTo={bookingsManagementPath('view', {
                                bookingId: String(
                                  row.booking_sessions.booking.id
                                ),
                                bookingTabSid: 'booking-sessions',
                                sessionFilter: `previous?sessionID=${row.job_id}`,
                              })}
                            />
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ color: 'text.secondary', pl: 1 }}
                            noWrap
                          >
                            {row.users.first_name} {row.users.last_name} (
                            {row.booking_sessions.booking.address.postcode}) on{' '}
                            {row.job_timestamp}
                          </Typography>
                        </Box>
                      ) : null}
                    </TableCell>
                    <TableCell align="left">{row.hours}</TableCell>
                    <TableCell align="right">
                      {fCurrency(row.price_per_hour)}
                    </TableCell>
                    <TableCell align="right">
                      {fCurrency(row.subtotal)}
                    </TableCell>
                    <TableCell align="center">
                      {row.cleaning_products}
                    </TableCell>
                    <TableCell align="right">{fCurrency(row.total)}</TableCell>
                  </TableRow>
                ))}

                <RowResultStyle>
                  <TableCell colSpan={5} />
                  <TableCell align="right">
                    <Box sx={{ mt: 2 }} />
                    <Typography>Subtotal</Typography>
                  </TableCell>
                  <TableCell align="right" width={120}>
                    <Box sx={{ mt: 2 }} />
                    <Typography>{fCurrency(total)}</Typography>
                  </TableCell>
                </RowResultStyle>

                <TableRow
                  key="extra_charges"
                  sx={{
                    borderBottom: (theme) =>
                      `solid 1px ${theme.palette.divider}`,
                  }}
                >
                  <TableCell> </TableCell>
                  <TableCell align="left" colSpan={5}>
                    <Box sx={{ maxWidth: 560 }}>
                      <Typography variant="subtitle2">DBS Checks</Typography>
                    </Box>
                  </TableCell>

                  <TableCell align="right" width={120}>
                    <Typography sx={{ color: 'error.main' }}>
                      {fCurrency(-35)}
                    </Typography>
                  </TableCell>
                </TableRow>

                {/* 
                <RowResultStyle>
                  <TableCell colSpan={3} />
                  <TableCell align="right">
                    <Typography>Taxes</Typography>
                  </TableCell>
                  <TableCell align="right" width={120}>
                    <Typography>{taxes && fCurrency(taxes)}</Typography>
                  </TableCell>
                </RowResultStyle> */}

                <RowResultStyle>
                  <TableCell colSpan={5} />
                  <TableCell align="right">
                    <Typography variant="h6">Total</Typography>
                  </TableCell>
                  <TableCell align="right" width={140}>
                    <Typography variant="h6">
                      {fCurrency(total - 35)}
                    </Typography>
                  </TableCell>
                </RowResultStyle>
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Divider sx={{ mt: 5 }} />

        <Grid container>
          <Grid item xs={12} md={9} sx={{ py: 3 }}>
            <Typography variant="subtitle2">NOTES</Typography>
            <Typography variant="body2">
              We appreciate your business.
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sx={{ py: 3, textAlign: 'right' }}>
            <Typography variant="subtitle2">Have a Question?</Typography>
            <Typography variant="body2">support@getsetclean.co.uk</Typography>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
