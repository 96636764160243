//
import { TableCell, TableRow, useTheme } from '@mui/material';
//
import { Label } from '@/components';
import { TableRowLink } from '@/components/table';
// hooks + utils
import { fDateAbbrevMonth } from '@/utils/formatTime';
import { invoicesPath } from '../routes/paths';
// types
import { InvoiceRun } from '@/features/invoices/types';

type PreviewTableCustomRowProps = {
  row: InvoiceRun;
};

export default function InvoicesRunTableRow({
  row,
}: PreviewTableCustomRowProps) {
  const theme = useTheme();

  const {
    id,
    total_jobs,
    processed_jobs,
    running,
    start_date,
    end_date,
    created_at,
  } = row;

  return (
    <>
      <TableRow hover>
        <TableCell width={10} />

        <TableCell align="left">
          <TableRowLink
            linkText={id}
            linkTo={invoicesPath('providerRunInvoicesList', {
              runId: String(id),
            })}
          />
        </TableCell>

        <TableCell align="left">
          {fDateAbbrevMonth(start_date, 'iii, dd MMM yyyy')}
        </TableCell>

        <TableCell align="left">
          {fDateAbbrevMonth(end_date, 'iii, dd MMM yyyy')}
        </TableCell>

        <TableCell align="left">
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={running === true ? 'info' : 'success'}
            sx={{ textTransform: 'capitalize' }}
          >
            {running ? 'Running' : 'Completed'}
          </Label>
        </TableCell>

        <TableCell align="left">
          {processed_jobs} / {total_jobs}
        </TableCell>

        <TableCell align="left">{fDateAbbrevMonth(created_at)}</TableCell>
      </TableRow>
    </>
  );
}
