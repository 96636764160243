import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
//
import {
  TableRow,
  TableCell,
  MenuItem,
  Stack,
  Typography,
  Card,
} from '@mui/material';
//
import { Iconify, Label } from '@/components';
import { TableMoreMenu } from '@/components/table';
import DialogSimple from '@/components/dialog/DialogSimple';
// API
import { deleteProviderTimeoff, updateProviderTimeOff } from '../../api';
// utils + hooks
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import { use3DotMenu } from '@/hooks/use3DotMenu';
import {
  TIME_OFF_STATUSES,
  createDialog,
  formatProviderAvailabilityDate,
  formatRepeatString,
  formatStartEndTimeForTableRowLabel,
  returnTypeName,
  sortRecurringDays,
  timeoffStatusColorPicker,
} from '../../utils';
// types
import { DayOff, TimeoffUpdateDialog } from '../../types';
import { ButtonColors, OffDaysRequestType } from '@/types';

type RowProps = {
  row: DayOff;
  canUpdate: boolean;
  canDelete: boolean;
  handleEdit: (id: string) => void;
  timeOffRequestTypes: OffDaysRequestType[];
};

export default function ProviderAvailabilityTableRow({
  row,
  canUpdate,
  canDelete,
  handleEdit,
  timeOffRequestTypes,
}: RowProps) {
  const queryClient = useQueryClient();
  const { userId } = useParams();
  const { successMsg, errorMsg } = useSnackbarMsg();

  const [selectedDialog, setSelectedDialog] =
    useState<TimeoffUpdateDialog | null>(null);

  const {
    id,
    type_id,
    start_date,
    end_date,
    status,
    notes,
    offday_recurrence,
  } = row;

  // ====================
  //    DERIVED VALUES
  // ====================

  const type = returnTypeName(type_id, timeOffRequestTypes);
  const startDate = formatProviderAvailabilityDate(start_date ?? '');
  const endDate = formatProviderAvailabilityDate(end_date ?? '');
  const repeatString = formatRepeatString(offday_recurrence);
  const offdayRecurrence = offday_recurrence
    ? `recurring - ${repeatString}`
    : 'one-off';
  const recurringDaysOfWeek = sortRecurringDays(offday_recurrence);
  const recurringStartEndTime = formatStartEndTimeForTableRowLabel(
    start_date,
    end_date
  );
  const filteredSatusesBasedOnCurentStatus = TIME_OFF_STATUSES.filter(
    (s) => s.name !== (status || '').toLowerCase()
  );
  const isDayOffInThePast = end_date ? new Date(end_date) < new Date() : false;

  // ====================
  //      3 DOT MENU
  // ====================

  const { openMenu, handleOpenMenu, handleCloseMenu } = use3DotMenu();

  // ====================
  //       QUERIES
  // ====================

  const deleteTimeoffMutation = useMutation({
    mutationFn: (timeoffId: string) =>
      deleteProviderTimeoff(String(userId), timeoffId),
    onSuccess: (_apiResponse, variables) => {
      queryClient.setQueryData(
        ['providerTimeoff', userId],
        // variables is the timeoffId
        (old: DayOff[] = []) => old.filter((b: any) => b.id !== variables)
      );
      queryClient.invalidateQueries(['providerTimeoff', userId]);
      successMsg(`Time-off successfully deleted`);
    },
    onError: (error) => {
      errorMsg(error, `There was an error while deleting time-off`);
    },
  });

  const updateTimeoffMutation = useMutation({
    mutationFn: (status: string) =>
      updateProviderTimeOff(String(userId), String(row.id), {
        type_id: row.type_id,
        status: status,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(['providerTimeoff', userId]);
      successMsg(`Time-off status updated`);
    },
    onError: (error) => {
      errorMsg(error, `There was an error while updating time-off status`);
    },
  });

  const handleDeleteTimeoff = async () => {
    deleteTimeoffMutation.mutate(String(row.id));
    setSelectedDialog(null);
  };
  const handleStatusChange = async (status: string) => {
    // approved, pending, rejected
    updateTimeoffMutation.mutate(status);
    setSelectedDialog(null);
  };
  const handleEditTimeoff = () => {
    // reset form values with the current row values
    // scroll up to the form + give visual cue
    handleEdit(String(row.id));
    handleCloseMenu();
  };

  // ====================
  //       DIALOGS
  // ====================

  const DIALOGS = [
    createDialog(
      'delete',
      'delete',
      'Delete Time-off',
      'error',
      handleDeleteTimeoff
    ),
    createDialog('rejected', 'reject', 'Reject time-off request', 'error', () =>
      handleStatusChange('rejected')
    ),
    createDialog(
      'approved',
      'approve',
      'Approve time-off request',
      'success',
      () => handleStatusChange('approved')
    ),
    createDialog(
      'pending',
      'Set pending',
      'Set time-off request to pending',
      'warning',
      () => handleStatusChange('pending')
    ),
  ];

  const handleOpenDialog = (dialogName: string) => {
    const selectedDialog = DIALOGS.find((d) => d.name === dialogName);
    selectedDialog
      ? setSelectedDialog(selectedDialog)
      : errorMsg(null, 'Dialog not found');
    handleCloseMenu();
  };

  // ====================

  return (
    <>
      <TableRow
        hover
        sx={{
          color: (theme) => (isDayOffInThePast ? theme.palette.grey[400] : ''),
        }}
      >
        <TableCell width={10} />
        <TableCell>{id}</TableCell>

        <TableCell align="left" sx={{ fontWeight: 600, color: 'inherit' }}>
          {type}
        </TableCell>

        <TableCell align="left" sx={{ color: 'inherit' }}>
          {startDate}
        </TableCell>

        <TableCell
          align="left"
          sx={{
            color: 'inherit',
            fontStyle: offday_recurrence ? 'italic' : 'inherit',
          }}
        >
          {offday_recurrence
            ? offday_recurrence.until ?? 'Indefinite'
            : endDate}
        </TableCell>

        <TableCell align="left">
          <Label
            color={
              isDayOffInThePast
                ? 'default'
                : timeoffStatusColorPicker(
                    status ? status.toLowerCase() : 'N/A'
                  )
            }
          >
            {status ?? 'N/A'}
          </Label>
        </TableCell>

        <TableCell align="left" sx={{ color: 'inherit' }}>
          {notes}
        </TableCell>

        <TableCell align="left">
          <Label color={isDayOffInThePast ? 'default' : 'info'}>
            {offdayRecurrence}
          </Label>
        </TableCell>
        <TableCell align="left">
          <>
            {!!recurringDaysOfWeek.length && (
              <Stack direction="row" spacing={0.4}>
                <Label color={isDayOffInThePast ? 'default' : 'info'}>
                  {recurringStartEndTime}
                </Label>
                {recurringDaysOfWeek.map((day) => (
                  <Label
                    color={isDayOffInThePast ? 'default' : 'info'}
                    key={day}
                  >
                    {day}
                  </Label>
                ))}
              </Stack>
            )}
          </>
        </TableCell>

        <TableCell align="right">
          {!isDayOffInThePast && (
            <TableMoreMenu
              open={openMenu}
              onOpen={handleOpenMenu}
              onClose={handleCloseMenu}
              arrow="top-right"
              actions={
                <>
                  {canUpdate && (
                    <MenuItem onClick={() => handleEditTimeoff()}>
                      <Iconify icon="eva:edit-outline" />
                      Edit
                    </MenuItem>
                  )}
                  {canUpdate &&
                    filteredSatusesBasedOnCurentStatus.map((status) => (
                      <MenuItem
                        key={status.id}
                        onClick={() => handleOpenDialog(status.name)}
                        sx={{
                          color: `${status.color}.main`,
                        }}
                      >
                        <Iconify icon={status.icon} />
                        {status.actionVerb.charAt(0).toUpperCase() +
                          status.actionVerb.slice(1)}
                      </MenuItem>
                    ))}
                  {canDelete && (
                    <MenuItem
                      onClick={() => handleOpenDialog('delete')}
                      sx={{ color: 'error.main' }}
                    >
                      <Iconify icon={'eva:trash-2-outline'} />
                      Delete
                    </MenuItem>
                  )}
                </>
              }
            />
          )}
        </TableCell>
      </TableRow>

      {/* DIALOGS */}
      {selectedDialog && (
        <DialogSimple
          dialogTitle={selectedDialog.title}
          open={!!selectedDialog}
          onClickButtonClose={() => setSelectedDialog(null)}
          onClickButtonLoading={selectedDialog.agreeFunction}
          loadingButtonText={selectedDialog.actionVerb}
          disagreeButtonText={selectedDialog.disagreeButtonText}
          loadingButtonColor={
            selectedDialog.loadingButtonColor ?? ('primary' as ButtonColors)
          }
          disagreeButtonColor="inherit"
        >
          <Stack spacing={2} sx={{ px: 3, pt: 1.5 }}>
            <Typography>
              Confirm to set status{' '}
              <span style={{ fontWeight: 600 }}>{selectedDialog.name}</span> for
              the following request:
            </Typography>
            <Card
              sx={{
                background: (theme) => theme.palette.grey[50],
                px: 2,
                py: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: 0.5,
              }}
            >
              <Typography>
                <span style={{ fontWeight: 600 }}>Type:</span> {type}{' '}
              </Typography>
              <Typography>
                <span style={{ fontWeight: 600 }}>Start:</span> {startDate}
              </Typography>
              <Typography>
                <span style={{ fontWeight: 600 }}>End:</span> {endDate}
              </Typography>
            </Card>
          </Stack>
        </DialogSimple>
      )}
    </>
  );
}
