//
import { isEmpty } from 'lodash';
// @mui
import {
  Avatar,
  Button,
  Chip,
  Divider,
  IconButton,
  Stack,
} from '@mui/material';
// hooks
import useResponsive from '@/hooks/useResponsive';
import useToggle from '@/hooks/useToggle';
// components
import Iconify from '@/components/Iconify';
import {
  AddNewAddress,
  AddressInfo,
  CustomErrorMessage,
  ListDialog,
  SearchAddress,
  SearchCleaner,
  SearchClient,
  SectionLabel,
} from '../../..';
// redux
import { dispatch, useSelector } from '@/stores';
import { removeCleaner } from '@/features/bookings-management/slices';
// utils
import {
  getDefaultClientAddress,
  setEvaIcon,
} from '@/features/bookings-management/utils';
// types
import { UserAddress } from '@/features/users/shared/types';

export default function ClientAddressCleanerTopSection() {
  // TODO: this component could be broken up into smaller components
  // wrapper -> client section, address section, cleaner section
  const upMd = useResponsive('up', 'md');
  const booking = useSelector((state) => state.booking);

  // ----------------------------------------
  //            TAB TOGGLE CONSTS
  // ----------------------------------------
  // TODO: refactor this using useReducer and keep the state in one place maybe?
  const {
    toggle: isClientDialogOpen,
    onOpen: openClientDialog,
    onClose: closeClientDialog,
  } = useToggle();

  const {
    toggle: isAddressDialogOpen,
    onOpen: openAddressDialog,
    onClose: closeAddressDialog,
  } = useToggle();

  const {
    toggle: isCleanerDialogOpen,
    onOpen: openCleanerDialog,
    onClose: closeCleanerDialog,
  } = useToggle();

  // ----------------------------------------
  //         TAB OPTIONS FOR DIALOGS
  // ----------------------------------------

  const ADDRESS_DIALOG_TABS = [
    {
      value: 'Search Existing Address',
      icon: <Iconify icon={'eva:search-outline'} width={20} height={20} />,
      component: (
        <SearchAddress
          onClose={closeAddressDialog}
          addressOptions={booking.client?.address || []}
        />
      ),
    },
    {
      value: 'Add New',
      icon: <Iconify icon={'eva:plus-outline'} width={20} height={20} />,
      component: <AddNewAddress onClose={closeAddressDialog} />,
    },
  ];

  const ClIENT_DIALOG_TABS = [
    {
      value: 'Search Existing Client',
      icon: <Iconify icon={'eva:search-outline'} width={20} height={20} />,
      component: <SearchClient onClose={closeClientDialog} />,
    },
  ];

  const ClEANER_DIALOG_TABS = [
    {
      value: 'Search Cleaner',
      icon: <Iconify icon={'eva:search-outline'} width={20} height={20} />,
      component: <SearchCleaner />,
    },
  ];

  // ----------------------------------------

  return (
    <Stack
      spacing={{ xs: 2, md: 5 }}
      direction={{ xs: 'column', md: 'row' }}
      divider={
        <Divider
          flexItem
          orientation={upMd ? 'vertical' : 'horizontal'}
          sx={{ borderStyle: 'dashed' }}
        />
      }
      sx={{ p: 3, minHeight: '20vh' }}
    >
      {/* CLIENT */}
      <Stack sx={{ width: 1 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 1 }}
        >
          <SectionLabel label="Client:" />

          <Button
            size="small"
            startIcon={<Iconify icon={setEvaIcon(isEmpty(booking.client))} />}
            onClick={openClientDialog}
          >
            {!isEmpty(booking.client) ? 'Change' : 'Add'}
          </Button>

          <ListDialog
            open={isClientDialogOpen}
            onClose={closeClientDialog}
            label="Select client"
            tabOptions={ClIENT_DIALOG_TABS}
            buttons={false}
          />
        </Stack>

        {!isEmpty(booking.client) && (
          <AddressInfo
            name={`${booking.client.first_name} ${booking.client.last_name}`}
            address={getDefaultClientAddress(booking)}
            phone={booking.client.phone}
            hideGoogleMapsButton={!!!getDefaultClientAddress(booking)}
            clientId={booking.client.id}
          />
        )}

        {booking.validationErrors.client && (
          <CustomErrorMessage label={booking.validationErrors.client} />
        )}
      </Stack>

      {/* ADDRESS */}
      <Stack sx={{ width: 1 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 1 }}
        >
          <SectionLabel label="Address:" />

          <Button
            size="small"
            startIcon={<Iconify icon={setEvaIcon(isEmpty(booking.address))} />}
            disabled={isEmpty(booking.client)}
            onClick={openAddressDialog}
          >
            {!isEmpty(booking.address) ? 'Change' : 'Add'}
          </Button>

          <ListDialog
            open={isAddressDialogOpen}
            onClose={closeAddressDialog}
            label="Select address"
            tabOptions={ADDRESS_DIALOG_TABS}
            buttons={false}
          />
        </Stack>

        {!isEmpty(booking.address) && (
          <AddressInfo
            name={(booking.address as UserAddress).name}
            address={(booking.address as UserAddress).formatted_address}
            postcode={(booking.address as UserAddress).postcode}
          />
        )}
        {booking.validationErrors.address && (
          <CustomErrorMessage label={booking.validationErrors.address} />
        )}
      </Stack>

      {/* CLEANER */}
      <Stack sx={{ width: 1 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 1 }}
        >
          <SectionLabel label="Cleaner:" />

          <Button
            size="small"
            startIcon={
              <Iconify icon={setEvaIcon(isEmpty(booking.booking_cleaners))} />
            }
            disabled={isEmpty(booking.client)}
            onClick={openCleanerDialog}
          >
            {!isEmpty(booking.booking_cleaners) ? 'Change' : 'Add'}
          </Button>

          <ListDialog
            open={isCleanerDialogOpen}
            onClose={closeCleanerDialog}
            label="Select cleaner"
            tabOptions={ClEANER_DIALOG_TABS}
            addButtonLabel="Assign Cleaner"
            isAddBtnDisabled={isEmpty(booking.booking_cleaners)}
            buttons={true}
          />
        </Stack>

        <Stack direction="column" spacing={1}>
          {booking.booking_cleaners.length > 0 &&
            booking.booking_cleaners.map((cleaner, index) => {
              const cleanerFullName = `${cleaner.first_name ?? 'N/A'} ${
                cleaner.last_name ?? 'N/A'
              }`;
              const avatarInitial = `${cleaner.first_name.charAt(0)}${
                cleaner.last_name?.charAt(0) ?? ''
              }`;
              return (
                <Chip
                  avatar={<Avatar>{avatarInitial}</Avatar>}
                  label={cleanerFullName}
                  key={index}
                  sx={{
                    maxWidth: '170px',
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                  color="primary"
                  onMouseDown={(event) => event.stopPropagation()}
                  onDelete={(e) => dispatch(removeCleaner(e.currentTarget.id))}
                  deleteIcon={
                    <IconButton
                      sx={{ ml: 'auto !important' }}
                      id={cleaner.id.toString()}
                    >
                      <Iconify icon="eva:close-outline" />
                    </IconButton>
                  }
                />
              );
            })}
        </Stack>
      </Stack>
    </Stack>
  );
}
