import { useNavigate } from 'react-router';
//
import {
  Chip,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  useTheme,
} from '@mui/material';
//
import { Avatar, Iconify, Label } from '@/components';
import { TableRowLink } from '@/components/table';
import NotesCell from './NotesCell';
// hooks + utils
import { fDateAbbrevMonth } from '@/utils/formatTime';
import { fCurrency } from '@/utils/formatNumber';
import { providersPath } from '@/features/users/providers';
import { invoicesPath } from '../routes/paths';
// types
import { ProviderInvoice } from '@/features/invoices/types';
import { LabelColors } from '@/types';

type PreviewTableCustomRowProps = {
  row: ProviderInvoice;
  hideProvider?: boolean;
};

export default function InvoicesRunInvoicesListTableRow({
  row,
  hideProvider,
}: PreviewTableCustomRowProps) {
  const theme = useTheme();
  const navigate = useNavigate();

  const {
    id,
    seen,
    accepted,
    date_from,
    date_to,
    due_date,
    total,
    cleaner_notes,
    admin_notes,
    regenerate,
    created_at,
    users,
    invoice_status,
    _count,
  } = row;

  const handleClickChip = () => {
    navigate(
      providersPath('edit', {
        userId: String(users?.id),
        tabSid: 'details',
      })
    );
  };

  return (
    <>
      <TableRow hover>
        <TableCell align="left">
          {regenerate && (
            <Tooltip
              title="Job has been modified. Requires new re-generation."
              placement="top"
            >
              <IconButton>
                <Iconify
                  icon={'eva:alert-triangle-fill'}
                  sx={{ color: 'orange' }}
                  width={24}
                  height={24}
                />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>

        <TableCell align="left">
          <TableRowLink
            linkText={id}
            linkTo={invoicesPath('providerInvoiceDetails', {
              invoiceId: String(id),
            })}
          />
        </TableCell>

        {hideProvider ? null : (
          <TableCell align="left" width={20}>
            {users && (
              <Chip
                size="small"
                avatar={
                  <Avatar alt="profile-picture" src={users?.profile_image} />
                }
                label={users?.first_name + ' ' + users?.last_name}
                color="default"
                clickable={true}
                onClick={handleClickChip}
              />
            )}
          </TableCell>
        )}
        <TableCell align="left">
          <Iconify
            icon={'eva:eye-fill'}
            width={24}
            sx={{
              color: seen ? theme.palette.success.dark : 'inherit',
              opacity: seen ? '100%' : '20%',
            }}
            title={seen ? 'Seen' : 'Not Seen'}
          />
        </TableCell>

        <TableCell align="left">
          <Iconify
            icon="eva:checkmark-circle-2-fill"
            width={20}
            sx={{
              color: accepted ? theme.palette.success.dark : 'inherit',
              opacity: accepted ? '100%' : '20%',
            }}
            title={accepted ? 'Accepted' : 'Not Accepted'}
          />
        </TableCell>

        <TableCell align="left">
          <Label
            variant={theme.palette.mode === 'light' ? 'filled' : 'ghost'}
            color={invoice_status.style_class as LabelColors}
            sx={{ textTransform: 'capitalize' }}
          >
            {invoice_status.value}
          </Label>
        </TableCell>

        <TableCell align="left">
          {fDateAbbrevMonth(date_from, 'iii, dd MMM yyyy')}
        </TableCell>

        <TableCell align="left">
          {fDateAbbrevMonth(date_to, 'iii, dd MMM yyyy')}
        </TableCell>

        <TableCell align="left">
          {fDateAbbrevMonth(due_date, 'iii, dd MMM yyyy')}
        </TableCell>

        <TableCell align="left">{fCurrency(total)}</TableCell>

        <TableCell align="left">{_count.provider_invoice_items}</TableCell>

        <TableCell align="left">
          <NotesCell notes={cleaner_notes} />
        </TableCell>

        <TableCell align="left">
          <NotesCell notes={admin_notes} />
        </TableCell>

        <TableCell align="left">{fDateAbbrevMonth(created_at)}</TableCell>
      </TableRow>
    </>
  );
}
