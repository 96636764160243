import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
//
import {
  Box,
  Card,
  Container,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { HeaderBreadcrumbs, Iconify, Page, Scrollbar } from '@/components';
import { SkeletonLoadingTable } from '@/components/skeleton';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
} from '@/components/table';
import { InvoicesRunInvoicesListTableRow } from '../components';
// api
import {
  getAllProviderInvoicesForRun,
  regenerateInvoiceRun,
} from '@/features/invoices/api';
// hookes + utils
import useSettings from '@/hooks/useSettings';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
import useTable, { emptyRows, getComparator } from '@/hooks/useTable';
//
import {
  PROVIDER_INVOICES_TABLE_HEAD,
  applySortFilterInvoicesList,
} from '@/features/invoices/utils';
import { PATH_USER } from '@/features/app';
import { invoicesPath } from '../routes/paths';

export default function InvoiceRunInvoicesList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    //
    selected,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { errorMsg, successMsg } = useSnackbarMsg();
  const { themeStretch } = useSettings();
  const { runId } = useParams();
  const queryClient = useQueryClient();

  // =================
  //      QUERIES
  // =================
  const { data: providerInvoices, isLoading: invoicesRunLoading } = useQuery({
    queryKey: ['providerInvoices', runId],
    queryFn: () => getAllProviderInvoicesForRun(Number(runId)),
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching invoices`);
    },
  });

  const regenerateInvoiceRunMutation = useMutation({
    mutationFn: () => regenerateInvoiceRun(Number(runId)),
    onSuccess: () => {
      successMsg('Invoice run successfully regenerated');
      queryClient.invalidateQueries(['providerInvoices', runId]);
    },
    onError: (error) => {
      errorMsg(error, 'Failed to regenerate invoice run');
    },
  });

  const handleRegenerateInvoices = () => regenerateInvoiceRunMutation.mutate();

  const dataFiltered = applySortFilterInvoicesList({
    tableData: providerInvoices || [],
    comparator: getComparator(order, orderBy),
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound = !dataFiltered.length;

  return (
    <Page title="Provider Invoices">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Provider Invoices"
          links={[
            { name: 'Dashboard', href: PATH_USER.root },
            { name: `Invoices Run`, href: invoicesPath('list') },
            { name: `Provider Invoices - Run #${runId}`, href: '' },
          ]}
        />
        <Card>
          {invoicesRunLoading ? (
            <SkeletonLoadingTable />
          ) : (
            <>
              <LoadingButton
                onClick={handleRegenerateInvoices}
                loading={regenerateInvoiceRunMutation.isLoading}
                variant="contained"
                color={'info'}
                startIcon={<Iconify icon="eva:refresh-outline" />}
                sx={{ m: 2 }}
              >
                Regenerate Modified Invoices
              </LoadingButton>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                  <Table size={dense ? 'small' : 'medium'}>
                    <TableHeadCustom
                      order={order}
                      orderBy={orderBy}
                      headLabel={PROVIDER_INVOICES_TABLE_HEAD}
                      rowCount={dataFiltered.length}
                      numSelected={selected.length}
                      onSort={onSort}
                    />
                    <TableBody>
                      {dataFiltered
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((preview) => (
                          <InvoicesRunInvoicesListTableRow
                            key={preview.id}
                            row={preview}
                          />
                        ))}

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(
                          page,
                          rowsPerPage,
                          dataFiltered.length
                        )}
                      />

                      <TableNoData
                        isNotFound={isNotFound}
                        title="No invoices found"
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <Box sx={{ position: 'relative' }}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={dataFiltered.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                />

                <FormControlLabel
                  control={<Switch checked={dense} onChange={onChangeDense} />}
                  label="Dense"
                  sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                />
              </Box>
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}
