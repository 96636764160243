import { lazyImport } from '@/utils/lazyImport';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import Page404 from '../pages/Page404';
import { Navigate } from 'react-router-dom';
import { PATH_USER } from '@/features/app';
import { PATH_SETTINGS } from '@/features/settings';
import { PATH_AUTH } from '@/features/auth';
import { PATH_ROLES } from '@/features/roles-and-permissions';
import { PATH_NOTIFICATIONS } from '@/features/notification';
import { PATH_AGENTDASHBOARD } from '@/features/agent-dashboard';
import { PATH_SERVICES_MANAGEMENT } from '@/features/services-management';
import { PATH_BOOKINGS_MANAGEMENT } from '@/features/bookings-management/routes/paths';
import { PATH_PROVIDER_MANAGEMENT } from '@/features/users/providers';
import { PATH_COUPONS } from '@/features/coupons';
import { PATH_CLIENT_MANAGEMENT } from '@/features/users/clients';
import { PATH_USER_MANAGEMENT } from '@/features/users/dashboard-users';
import { PATH_REVIEWS } from '@/features/reviews';
import { PATH_JOB_SCHEDULER } from '@/features/job-scheduler/routes/paths';
import { PATH_INVOICES } from '@/features/invoices';
import { PATH_TASKS } from '@/features/tasks';

const { AppRoutes } = lazyImport(() => import('@/features/app'), 'AppRoutes');

const { ProviderRoutes } = lazyImport(
  () => import('@/features/users/providers'),
  'ProviderRoutes'
);

const { AuthRoutes } = lazyImport(
  () => import('@/features/auth'),
  'AuthRoutes'
);

const { SettingsRoutes } = lazyImport(
  () => import('@/features/settings'),
  'SettingsRoutes'
);

const { RolesRoutes } = lazyImport(
  () => import('@/features/roles-and-permissions'),
  'RolesRoutes'
);

const { NotificationsRoutes } = lazyImport(
  () => import('@/features/notification'),
  'NotificationsRoutes'
);

const { DashboardRoutes } = lazyImport(
  () => import('@/features/agent-dashboard'),
  'DashboardRoutes'
);
const { ServicesManagementRoutes } = lazyImport(
  () => import('@/features/services-management'),
  'ServicesManagementRoutes'
);
const { BookingsManagementRoutes } = lazyImport(
  () => import('@/features/bookings-management'),
  'BookingsManagementRoutes'
);
const { CouponsRoutes } = lazyImport(
  () => import('@/features/coupons'),
  'CouponsRoutes'
);
const { ClientManagementRoutes } = lazyImport(
  () => import('@/features/users/clients'),
  'ClientManagementRoutes'
);
const { UserRoutes } = lazyImport(
  () => import('@/features/users/dashboard-users'),
  'UserRoutes'
);
const { ReviewsRoutes } = lazyImport(
  () => import('@/features/reviews'),
  'ReviewsRoutes'
);

const { JobSchedulerRoutes } = lazyImport(
  () => import('@/features/job-scheduler'),
  'JobSchedulerRoutes'
);

const { InvoicesRoutes } = lazyImport(
  () => import('@/features/invoices'),
  'InvoicesRoutes'
);

const { TasksRoutes } = lazyImport(
  () => import('@/features/tasks'),
  'TasksRoutes'
);

export const protectedRoutes = [
  {
    path: `${PATH_AUTH.root}/*`,
    element: <AuthRoutes />,
  },
  {
    path: `${PATH_NOTIFICATIONS.root}/*`,
    element: <NotificationsRoutes />,
  },
  {
    path: `${PATH_USER.root}/*`,
    element: <AppRoutes />,
  },
  {
    path: `${PATH_PROVIDER_MANAGEMENT.root}/*`,
    element: <ProviderRoutes />,
  },
  {
    path: `${PATH_USER_MANAGEMENT.root}/*`,
    element: <UserRoutes />,
  },
  {
    path: `${PATH_ROLES.root}/*`,
    element: <RolesRoutes />,
  },
  {
    path: `${PATH_SETTINGS.root}/*`,
    element: <SettingsRoutes />,
  },
  {
    path: `${PATH_USER.root}/*`,
    element: <UserRoutes />,
  },
  {
    path: `${PATH_AGENTDASHBOARD.root}/*`,
    element: <DashboardRoutes />,
  },
  {
    path: `${PATH_SERVICES_MANAGEMENT.root}/*`,
    element: <ServicesManagementRoutes />,
  },
  {
    path: `${PATH_BOOKINGS_MANAGEMENT.root}/*`,
    element: <BookingsManagementRoutes />,
  },
  {
    path: `${PATH_COUPONS.root}/*`,
    element: <CouponsRoutes />,
  },
  {
    path: `${PATH_CLIENT_MANAGEMENT.root}/*`,
    element: <ClientManagementRoutes />,
  },
  {
    path: `${PATH_REVIEWS.root}/*`,
    element: <ReviewsRoutes />,
  },
  {
    path: `${PATH_JOB_SCHEDULER.root}/*`,
    element: <JobSchedulerRoutes />,
  },
  {
    path: `${PATH_INVOICES.root}/*`,
    element: <InvoicesRoutes />,
  },
  {
    path: `${PATH_TASKS.root}/*`,
    element: <TasksRoutes />,
  },
  { path: '/', element: <Navigate to={PATH_USER.root} /> },
  {
    path: '*',
    element: <LogoOnlyLayout />,
    children: [
      { path: '404', element: <Page404 /> },
      { path: '*', element: <Navigate to="/404" replace /> },
    ],
  },
];
