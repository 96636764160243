// mui
import { Grid } from '@mui/material';
// components
import { RightSidebarTitle } from '..';
import { JobScheduler } from '@/features/job-scheduler/pages';

export default function RightSidebarScheduler() {
  return (
    <Grid
      item
      container
      direction="row"
      spacing={2}
      sx={{
        m: 0,
        backgroundColor: 'background.paper',
        width: '100%',
        height: '100vh',
        zIndex: 5,
      }}
    >
      <>
        <RightSidebarTitle title="Scheduler" />
        <JobScheduler />
      </>
    </Grid>
  );
}
