import { useState, useMemo, useCallback } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
// mobiscroll
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {
  Eventcalendar,
  MbscCalendarEventData,
  MbscEventClickEvent,
  MbscEventCreateEvent,
  MbscEventUpdateEvent,
  MbscEventUpdatedEvent,
  MbscResource,
  MbscSelectedDateChangeEvent,
  snackbar,
  toast,
} from '@mobiscroll/react';
// components
import { Box, Stack, useTheme } from '@mui/material';
import {
  CleaningJobDetailsDialog,
  SchedulerPageSkeleton,
  UnassignedEventsContainer,
} from '../components';
// custom mobiscroll components
import {
  MbscCalendarHeader,
  MbscCleanerCell,
  MbscScheduleEvent,
} from '../components/mbsc-custom-components';
// API
import {
  getSchedulerBookingSessions,
  updateBookingSession,
} from '@/features/bookings-management/api';
import { getActiveProviders } from '@/features/users/shared/api';
// utils + hooks
import useToggle from '@/hooks/useToggle';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
//
import {
  applySortFilterCleaningJobs,
  formatDateToISOString,
  formatMbscDateType,
  getNextPrevDay,
  invalidEvents,
  mbscCalendarConfig,
  parseBookingSessionData,
  retrieveCleaner,
  schedulerSx,
  transformBookingSession,
} from '../utils';
// types
import { EventcalendarBase } from '@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar';
import { ColorSchema } from '@/theme/palette';
import { TransformedBooking } from '../types';

// =====================================================================================

export default function JobScheduler() {
  const { errorMsg } = useSnackbarMsg();
  const theme = useTheme();
  const schedulerViewConfig = useMemo(() => mbscCalendarConfig(), []);

  // =============================================
  //    SELECTED EVENT + CLEANER + DATE CHANGE
  // =============================================
  // This is used used to store the currently selected date which can be changed in the header.
  // It is also used as a query key. Changing this will trigger a refetch of the booking sessions.
  const [selectedDate, setSelectedDate] = useState<string | Date>(
    format(new Date(), 'yyyy-MM-dd')
  );

  const [currentSessionId, setCurrentSessionId] = useState<number | null>(null);
  const [selectedCleanerId, setSelectedCleanerId] = useState<number>(0);

  // =================================
  //     REACT QUERY / FETCH DATA
  // =================================
  const queryClient = useQueryClient();

  const nextDay = getNextPrevDay(new Date(selectedDate), 'next');
  const prevDay = getNextPrevDay(new Date(selectedDate), 'prev');
  const { data: bookingSessions, isLoading: isBookingSessionsDataLoading } =
    useQuery({
      queryKey: ['scheduler', selectedDate], // when selectedDate changes the cache gets invalidated -- make sure to include all the querykeys that are used here
      queryFn: () =>
        getSchedulerBookingSessions(`?startDate=${prevDay}&endDate=${nextDay}`),
      onError: (error) => errorMsg(error, 'Error fetching cleaning jobs'),
      select: (data) => parseBookingSessionData(data) || [],
    });

  const { data: allCleaners } = useQuery({
    queryKey: ['cleaners'],
    queryFn: getActiveProviders,
    onError: (error) => errorMsg(error, 'Error fetching cleaners'),
  });

  // =================================
  //     REACT QUERY / UPDATE DATA
  // =================================

  // more info on useMutation --> https://www.youtube.com/watch?v=DocXo3gqGdI&t=4431s
  const updateBookingSessionMutation = useMutation({
    // mutate function will make the API call
    mutationFn: (updatedSession: {
      id: number;
      new_cleaner: number;
      old_cleaner: number;
    }) =>
      updateBookingSession(updatedSession.id, {
        booking_cleaners:
          updatedSession.new_cleaner === 0 ? [] : [updatedSession.new_cleaner],
        // include_unassigned: true,
      }),
    // onMutate fires before the mutationFn and optimistically updates the cache
    onMutate: (updatedSessionFromScheduler) => {
      const oldSchedulerData = queryClient.getQueryData([
        'scheduler',
        selectedDate,
      ]);

      if (queryClient.getQueryData(['scheduler', selectedDate])) {
        // TODO: TS any
        queryClient.setQueryData(['scheduler', selectedDate], (old: any) =>
          old.map((b: any) =>
            // here we need to transform the date/time again
            b.id === updatedSessionFromScheduler.id
              ? {
                  ...b,
                  start: formatDateToISOString(b.start),
                  end: formatDateToISOString(b.end),
                  booking_session_cleaners: [
                    retrieveCleaner(
                      updatedSessionFromScheduler.new_cleaner,
                      allCleaners,
                      true
                    ),
                  ],
                }
              : b
          )
        );
      }

      // this will be used in the onError callback
      return () =>
        queryClient.setQueryData(['scheduler', selectedDate], oldSchedulerData);
    },

    onSuccess: (updatedSessionFromApi) => {
      if (queryClient.getQueryData(['scheduler', selectedDate])) {
        // if we have query data for posts we perform this operation
        queryClient.setQueryData(['scheduler', selectedDate], (old: any) =>
          old.map((b: any) =>
            b.id === updatedSessionFromApi.id
              ? transformBookingSession(updatedSessionFromApi)
              : b
          )
        );
      } else {
        // if there was no query for posts yet, we create a new array with the current updated booking
        queryClient.setQueryData(
          ['scheduler', selectedDate],
          [updatedSessionFromApi]
        );
      }
    },

    onError: (error, _v, rollback) => {
      // this fn is returned from onMutate callback
      rollback && rollback();
      errorMsg(error, `Something went wrong while updating booking`);
    },

    onSettled: (_data, error, mutationData) => {
      // if mutation was successful, we show a snackbar with the option to undo the mutation
      if (!!!error) {
        snackbar({
          message: `#${mutationData.id} assigned to ${retrieveCleaner(
            mutationData.new_cleaner,
            allCleaners
          )}`,
          color: 'success',
          button: {
            action: () => {
              updateBookingSessionMutation.mutate({
                id: mutationData.id,
                new_cleaner: mutationData.old_cleaner,
                old_cleaner: mutationData.new_cleaner,
              });
            },
            text: 'Undo',
          },
          duration: 6000,
        });
      }
      queryClient.invalidateQueries(['scheduler', selectedDate]);
      queryClient.invalidateQueries(['unassignedJobs']);
    },
  });

  // ==================================================
  //    FILTER CLEANER / SET DATE IN CALENDAR HEADER
  // ==================================================
  const handleCleanerFilter = useCallback((cleanerId: number) => {
    setSelectedCleanerId(cleanerId || 0);
  }, []);

  const [filteredBookings, filteredCleaners] = applySortFilterCleaningJobs({
    bookingSessions,
    cleaners: allCleaners,
    cleanerId: selectedCleanerId,
  });

  const handleSelectedDateChange = useCallback(
    (args: MbscSelectedDateChangeEvent) => {
      const { date } = args;
      const transformedDate = formatMbscDateType(date);
      setSelectedDate(transformedDate);
    },
    []
  );

  // ====================
  //   EDIT JOB DIALOG
  // ====================

  const {
    toggle: isJobDialogOpen,
    onClose: closeJobDialog,
    onOpen: openJobDialog,
  } = useToggle();

  const handleEditSession = (event: MbscEventClickEvent) => {
    setCurrentSessionId(Number(event.event.id));
    openJobDialog();
  };

  // =============================
  //    SCHEDULE EVENT/BOOKING
  // =============================
  const styleBgColor = useCallback(
    (color: ColorSchema | undefined) => {
      if (!color || (color as string) === 'default') return 'lightgrey';
      return theme.palette[color].light;
    },
    [theme.palette]
  );

  const renderScheduleEvent = useCallback(
    (data: MbscCalendarEventData) => (
      <Box
        sx={{
          border: '1px solid grey',
          borderRadius: 1,
          background: styleBgColor(data.original?.cssClass as ColorSchema),
          p: 0.2,
          pl: 0.5,
        }}
      >
        <MbscScheduleEvent data={data} />
      </Box>
    ),
    [styleBgColor]
  );

  // =================================
  //       MBSC CUSTOM COMPONENTS
  // =================================

  const renderHeader = useCallback(
    () => <MbscCalendarHeader handleCleanerFilter={handleCleanerFilter} />,
    [handleCleanerFilter]
  );

  const renderResource = useCallback(
    (r: MbscResource) => <MbscCleanerCell cleanerResource={r} />,
    []
  );

  // =======================
  //     EVENT HANDLERS
  // =======================
  // this fires when drag and drop event between cleaners
  // that's where we update the database when the event is recurring
  const handleEventUpdate = useCallback(
    (args: MbscEventUpdateEvent) => {
      const { event, oldEvent, newEvent } = args;
      if (event.recurring) {
        updateBookingSessionMutation.mutate({
          id: Number(event.id),
          new_cleaner: newEvent?.resource as number,
          old_cleaner: oldEvent?.resource as number,
        });
      }
    },
    [updateBookingSessionMutation]
  );
  // this fires when drag and drop event between cleaners
  // that's where we update the database if the event is non-recurring
  const handleEventUpdated = useCallback(
    (event: MbscEventUpdatedEvent, _inst: EventcalendarBase) => {
      // update changes in DB
      if (!event.event.recurring) {
        updateBookingSessionMutation.mutate({
          id: Number(event.event.id),
          new_cleaner: event.event.resource as number,
          old_cleaner: event.oldEvent?.resource as number,
        });
      }
    },
    [updateBookingSessionMutation]
  );

  // this fires when drag and drop event from sidebar to calendar or recurrring DND event is created
  const handleEventCreate = useCallback(
    (args: MbscEventCreateEvent) => {
      const cachedUnassignedJobs: TransformedBooking[] | undefined =
        queryClient.getQueryData(['unassignedJobs']);

      queryClient.setQueryData(
        ['unassignedJobs'],
        (cachedUnassignedJobs || []).filter(
          (unassignedJob) => unassignedJob.id !== args.event.id
        )
      );
      updateBookingSessionMutation.mutate({
        id: Number(args.event.id),
        new_cleaner: args.event.resource as number,
        old_cleaner: 0,
      });
    },
    [queryClient, updateBookingSessionMutation]
  );

  const eventUpdateFail = useCallback(() => {
    toast({
      message: `Cannot have overlapping jobs`,
      color: 'warning',
    });
  }, []);

  // ====================
  //       SKELETON
  // ====================
  if (isBookingSessionsDataLoading) {
    return <SchedulerPageSkeleton />;
  }
  // ===================

  return (
    <Box sx={{ width: '100%' }}>
      <Stack direction="row" sx={{ position: 'relative' }} width={'100%'}>
        <Box sx={schedulerSx}>
          <Eventcalendar
            dataTimezone="bst"
            view={schedulerViewConfig}
            data={filteredBookings}
            invalid={invalidEvents}
            resources={filteredCleaners}
            //
            dragToMove={true}
            dragInTime={false}
            dragToResize={false}
            eventOverlap={false}
            externalDrop={true}
            externalDrag={true}
            //
            selectedDate={selectedDate}
            onSelectedDateChange={handleSelectedDateChange}
            //
            renderScheduleEvent={renderScheduleEvent}
            renderHeader={renderHeader}
            renderResource={renderResource}
            //
            onEventDoubleClick={handleEditSession}
            onEventUpdate={handleEventUpdate}
            onEventUpdated={handleEventUpdated} // https://docs.mobiscroll.com/react/eventcalendar#event-onEventUpdated
            onEventCreate={handleEventCreate}
            onEventUpdateFailed={eventUpdateFail}
            // themes
            theme="ios"
            themeVariant="light"
          />
        </Box>
        {/* RIGHT SIDE CONTAINER */}
        <Box
          sx={{
            width: '15%',
            position: 'absolute',
            right: 0,
            top: 0,
          }}
        >
          <UnassignedEventsContainer selectedDate={selectedDate} />
        </Box>
      </Stack>

      {/* DIALOG */}
      <CleaningJobDetailsDialog
        isJobDialogOpen={isJobDialogOpen}
        closeJobDialog={closeJobDialog}
        currentSessionId={currentSessionId}
      />
    </Box>
  );
}
