import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
// @mui
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  FormControlLabel,
  Switch,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import { Page, Iconify, Scrollbar, HeaderBreadcrumbs } from '@/components';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
} from '@/components/table';
import { SkeletonLoadingTable } from '@/components/skeleton';
import { ProviderTableRow, ProviderTableToolbar } from '../components';
// API
import { deleteUser, getProviders } from '@/features/users/shared/api';
import { getSettingsData } from '@/features/app/api';
// hooks +  utils
import useTabs from '@/hooks/useTabs';
import useSettings from '@/hooks/useSettings';
import useTable, { getComparator, emptyRows } from '@/hooks/useTable';
import { useGetAccess } from '@/hooks/useAccess';
import { useSnackbarMsg } from '@/hooks/useSnackbarMsg';
//
import { PROVIDERS_TABLE_HEAD, applySortFilterProviders } from '../utils';
import { PATH_USER } from '@/features/app';
import { providersPath, ROOTS_PROVIDER_MANAGEMENT } from '../routes/paths';
// types
import { User } from '@/features/users/shared/types';

// ----------------------------------------------------------------------

export default function ProviderList() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const navigate = useNavigate();
  const allow = useGetAccess('users');
  const { themeStretch } = useSettings();
  const { errorMsg, successMsg } = useSnackbarMsg();
  const queryClient = useQueryClient();

  // =================
  //      QUERIES
  // =================

  // SETTINGS
  const { data: settingsData, isLoading: statusesLoading } = useQuery({
    queryKey: ['settings'],
    queryFn: () => getSettingsData(),
    onError: (error) => {
      errorMsg(error, `Something went wrong while fetching settings data `);
    },
    select: (data) => ({
      statusOptions: [
        'all',
        ...data.userStatuses.map((s) => s.display.toLowerCase()),
      ],
      cityOptions: [{ id: 0, name: 'All' }, ...data.cities],
    }),
    // TODO: revisit these settings once MVP is ready to be launched
    // staleTime: 24 * 60 * 60 * 1000, // update every 24 hours
    // cacheTime: 24 * 60 * 60 * 1000, // update every 24 hours
  });

  // PROVIDERS
  const { data: providers, isLoading: isProviderDataLoading } = useQuery({
    queryKey: ['providers'],
    queryFn: getProviders,
    onError: (error) =>
      errorMsg(error, 'There was an error while fetching providers'),
  });

  // =============================
  //      FILTER DELETE EDIT
  // =============================
  const [filterName, setFilterName] = useState('');
  const [filterCity, setFilterCity] = useState(0);

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } =
    useTabs('active');

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleFilterCity = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterCity(Number(event.target.value));
    setPage(0);
  };

  const deleteClientMutation = useMutation({
    mutationFn: (id: number) => deleteUser(id),
    onSuccess: (_apiResponse, variables) => {
      queryClient.setQueryData(['providers'], (old: User[] = []) =>
        old.filter((b) => b.id !== variables)
      );
      queryClient.invalidateQueries(['providers']);
      setPage(0);
      successMsg(`Provider succesfully deleted`);
    },
    onError: (error) => {
      errorMsg(error, `There was an error while deleting provider`);
    },
  });

  const handleDeleteRow = async (id: number) => {
    deleteClientMutation.mutate(id);
  };

  const handleEditRow = (id: number) => {
    navigate(providersPath('edit', { userId: String(id), tabSid: 'details' }));
  };

  const dataFiltered = applySortFilterProviders({
    tableData: providers || [],
    comparator: getComparator(order, orderBy),
    filterName,
    filterCity,
    filterStatus,
  });

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterCity) ||
    (!dataFiltered.length && !!filterStatus);

  // ----------------------------------------------------------------------

  return (
    <Page title="Providers: List">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Provider List"
          links={[
            { name: 'Dashboard', href: PATH_USER.root },
            { name: 'Provider', href: ROOTS_PROVIDER_MANAGEMENT },

            { name: 'List' },
          ]}
          action={
            allow.canCreate && (
              <Button
                variant="contained"
                component={RouterLink}
                to={providersPath('new')}
                startIcon={<Iconify icon={'eva:plus-fill'} />}
              >
                Add Provider
              </Button>
            )
          }
        />

        <Card>
          {isProviderDataLoading || statusesLoading ? (
            <SkeletonLoadingTable />
          ) : (
            <>
              <Tabs
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
                value={filterStatus}
                onChange={onChangeFilterStatus}
                sx={{ px: 2, bgcolor: 'background.neutral' }}
              >
                {(settingsData?.statusOptions || []).map((tab) => (
                  <Tab disableRipple key={tab} label={tab} value={tab} />
                ))}
              </Tabs>

              <Divider />

              <ProviderTableToolbar
                filterName={filterName}
                filterCity={filterCity}
                onFilterName={handleFilterName}
                onFilterCity={handleFilterCity}
                optionsCities={settingsData?.cityOptions || []}
              />

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                  <Table size={dense ? 'small' : 'medium'}>
                    <TableHeadCustom
                      order={order}
                      orderBy={orderBy}
                      headLabel={PROVIDERS_TABLE_HEAD}
                      rowCount={dataFiltered.length}
                      numSelected={selected.length}
                      onSort={onSort}
                    />
                    <TableBody>
                      {dataFiltered
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((provider) => (
                          <ProviderTableRow
                            key={provider.id}
                            row={provider}
                            selected={selected.includes(provider.id.toString())}
                            onDeleteRow={() => handleDeleteRow(provider.id)}
                            onEditRow={() => handleEditRow(provider.id)}
                            canDelete={allow.canDelete}
                            canUpdate={allow.canUpdate}
                          />
                        ))}

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(
                          page,
                          rowsPerPage,
                          dataFiltered.length
                        )}
                      />

                      <TableNoData
                        isNotFound={isNotFound}
                        title="No provider found"
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>

              <Box sx={{ position: 'relative' }}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={dataFiltered.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                />

                <FormControlLabel
                  control={<Switch checked={dense} onChange={onChangeDense} />}
                  label="Dense"
                  sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                />
              </Box>
            </>
          )}
        </Card>
      </Container>
    </Page>
  );
}
